import * as React from 'react';
import { useTabletActionsBarStyles } from './TabletActionBar.styles';
import { Text, mergeClasses } from '@fluentui/react-components';
import { MessagesViewType } from '@models/ui/MessagesViewType';
import MessageViewSwitcher from '@components/MessageViewSwitcher';
import { NavigationBar } from '@components/NavigationBar';

export interface ITabletActionsBarProps {
    mainZoneMessage?: string;
    secondaryZoneMessage?: string;
    searchComponent?: React.ReactElement;
    additionalActionsComponent?: React.ReactElement;
    filtersComponent?: React.ReactElement;
    extraFiltersComponent?: React.ReactElement;
    filtersTagsComponent?: React.ReactElement;
    viewConfig?: {
        current: MessagesViewType;
        onChange: (view: MessagesViewType) => void;
    };
}

const TabletActionsBar = ({
    additionalActionsComponent,
    extraFiltersComponent,
    filtersComponent,
    searchComponent,
    secondaryZoneMessage,
    viewConfig
}: ITabletActionsBarProps): React.ReactElement => {
    const styles = useTabletActionsBarStyles();
    return (
        <div>
            <div className={mergeClasses(styles.navigationZonesContainer)}>
                <div className={styles.navigationMainZoneContainer}>
                    <NavigationBar asTags />
                </div>

                <div className={styles.navigationSecondaryZoneContainer}>
                    {(!!filtersComponent && filtersComponent) || <></>}
                    {(!!additionalActionsComponent && additionalActionsComponent) || <></>}
                </div>
            </div>

            <div className={styles.zonesContainer}>
                <div className={styles.mainZoneContainer}>
                    {!!extraFiltersComponent && (
                        <div className={styles.mainZoneActionContainer}>
                            {extraFiltersComponent}
                        </div>
                    )}
                    {!!searchComponent && (
                        <div className={styles.mainZoneActionContainer}>{searchComponent}</div>
                    )}
                </div>
                <div className={mergeClasses(styles.secondaryZoneContainer)}>
                    <div className={styles.secondaryZoneMessageContainer}>
                        {!!secondaryZoneMessage && (
                            <Text className={styles.secondaryZoneMessage} as="h4">
                                {secondaryZoneMessage}
                            </Text>
                        )}
                    </div>
                    {!!viewConfig && (
                        <MessageViewSwitcher
                            active={viewConfig.current}
                            onViewChange={viewConfig.onChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TabletActionsBar;
