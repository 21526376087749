import { apiClient } from '@api/services/client';
import cache from '@api/services/cache';

import { MessageCategoryData, MessagePeriodData, MessageStrategicFocusData } from '@api/models';
import { AppLinks } from '@api/models/AppLinks';

const CACHE_15MIN = 15;
const CACHE_1Day = 24 * 60 * 1;

export const getMessageCategories = async (): Promise<MessageCategoryData[]> => {
    const url = `/api/meta/categories`;
    const res = cache.get<MessageCategoryData[]>(url);
    if (!res) {
        const response = await apiClient.get<MessageCategoryData[]>(encodeURI(url));
        const data = response.data;
        cache.set(url, data, CACHE_15MIN);
        return data;
    } else {
        return res;
    }
};

export const getMessageStrategicFocuses = async (): Promise<MessageStrategicFocusData[]> => {
    const url = `/api/meta/strategicFocuses`;
    const res = cache.get<MessageStrategicFocusData[]>(url);
    if (!res) {
        const response = await apiClient.get<MessageStrategicFocusData[]>(encodeURI(url));
        const data = response.data;
        cache.set(url, data, CACHE_15MIN);
        return data;
    } else {
        return res;
    }
};

export const getMessagePeriods = async (): Promise<MessagePeriodData[]> => {
    const url = `/api/meta/periods`;
    const res = cache.get<MessagePeriodData[]>(url);
    if (!res) {
        const response = await apiClient.post<MessagePeriodData[]>(
            encodeURI(url),
            JSON.stringify(new Date())
        );
        const data = response.data;
        cache.set(url, data, CACHE_15MIN);
        return data;
    } else {
        return res;
    }
};

export const getLinks = async (): Promise<AppLinks> => {
    const url = `/api/meta/Links`;
    const res = cache.get<AppLinks>(url);
    if (!res) {
        const response = await apiClient.get<AppLinks>(encodeURI(url));
        const data = response.data;
        cache.set(url, data, CACHE_1Day);
        return data;
    } else {
        return res;
    }
};
