import { makeStyles } from '@configs/theme/v9';

export const useMessagePublishDateStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    date: {
        paddingLeft: '5px'
    }
});
