import { Theme } from "@fluentui/react-components";
import { Theme as ThemeV8 } from "@fluentui/react";
import { createV8Theme } from "@fluentui/react-migration-v8-v9";

export const getV8TeamsTheme = (themeV9: Theme): ThemeV8 => {
    const brandTeams = {
        10: `#2b2b40`,
        20: `#2f2f4a`,
        30: `#333357`,
        40: `#383966`,
        50: `#3d3e78`,
        60: `#444791`,
        70: `#4f52b2`,
        80: `#5b5fc7`,
        90: `#7579eb`,
        100: `#7f85f5`,
        110: `#9299f7`,
        120: `#aab1fa`,
        130: `#b6bcfa`,
        140: `#c5cbfa`,
        150: `#dce0fa`,
        160: `#e8ebfa`
    };

    return createV8Theme(brandTeams, themeV9, false);
}
