import { TitleValuePair } from '../models/data/TitleValuePair';

export const getImportanceLevels = (
    translation: (key: string) => string
): TitleValuePair<number>[] => [
    {
        id: 1,
        title: translation('ImportanceLevels.Critical')
    },
    {
        id: 0,
        title: translation('ImportanceLevels.NotCritical')
    }
];

export const parseCriticalToImportance = (
    flag: boolean,
    translation: (key: string) => string
): TitleValuePair<number> => {
    const vals = getImportanceLevels(translation);
    const res = vals.find((item) => item.id === +flag);
    return res || { id: -1, title: '' };
};
