import * as React from 'react';
import {
    Button,
    Menu,
    MenuItemLink,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Overflow,
    OverflowItem,
    Tab,
    TabList,
    Tag,
    TagGroup,
    mergeClasses,
    useIsOverflowItemVisible,
    useOverflowMenu
} from '@fluentui/react-components';
import { bundleIcon, MoreHorizontalFilled, MoreHorizontalRegular } from '@fluentui/react-icons';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useNavBarStyles, useOverflowMenuStyles } from './NavigationBar.styles';
import { useAppDispatch, useStateSelector } from '@state';
import { routes } from '@configs/pages';
import { resetFiltersState } from '@state/filters';

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);

const getRoute = (): string =>
    window.location.href.replace(window.location.origin, '').toLowerCase();

type Tab = {
    title: string;
    route: string;
};

type OverflowMenuItemProps = {
    tab: Tab;
    onClick: (tabId: string) => void;
};

type OverflowMenuProps = {
    tabs: Tab[];
    onTabSelect: (tabId: string) => void;
};

const OverflowMenuItem = (props: OverflowMenuItemProps): React.ReactElement | null => {
    const { tab, onClick } = props;
    const isVisible = useIsOverflowItemVisible(tab.route);
    if (isVisible) {
        return null;
    }

    const onClickLink: React.MouseEventHandler<HTMLAnchorElement> | undefined = (e) => {
        e.preventDefault();
        onClick(tab.route);
    };

    return (
        <MenuItemLink key={tab.route} onClick={onClickLink} href={tab.route}>
            <div>{tab.title}</div>
        </MenuItemLink>
    );
};

const OverflowMenu = ({ tabs, onTabSelect }: OverflowMenuProps): React.ReactElement | null => {
    const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>();

    const styles = useOverflowMenuStyles();

    if (!isOverflowing) {
        return null;
    }
    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    appearance="transparent"
                    className={styles.menuButton}
                    ref={ref}
                    icon={<MoreHorizontal />}
                    aria-label={`${overflowCount} more tabs`}
                    role="tab"
                />
            </MenuTrigger>
            <MenuPopover>
                <MenuList className={styles.menu}>
                    {tabs.map((tab) => (
                        <OverflowMenuItem key={tab.route} tab={tab} onClick={onTabSelect} />
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

export interface INavigationBarProps {
    asTags?: boolean;
}

export const NavigationBar = ({ asTags }: INavigationBarProps): React.ReactElement => {
    const styles = useNavBarStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentUserPermissions = useStateSelector((x) => x.app.currentUserPermissions);

    const [selectedRoute, setSelectedRoute] = React.useState(getRoute());

    const onTabSelect = React.useCallback(
        (route: string) => {
            setSelectedRoute(route);
            dispatch(resetFiltersState());
            navigate(route);
        },
        [navigate, dispatch]
    );

    const tabs: Tab[] = [
        {
            route: routes.myMessages,
            title: t('MyMessagesPage.PageTitle')
        },
        {
            route: routes.moreMessages,
            title: t('MoreMessagesPage.PageTitle')
        }
    ];

    if (currentUserPermissions.admin) {
        tabs.push({
            route: routes.admin,
            title: t('AdminPage.PageTitle')
        });
    }

    if (currentUserPermissions.approver) {
        tabs.push({
            route: routes.designatedApprover,
            title: t('DesignatedApproverPage.PageTitle')
        });
    }

    return (
        <div className={styles.root}>
            <Overflow minimumVisible={2} overflowAxis="horizontal" key={tabs.length}>
                {asTags ? (
                    <TagGroup className={styles.tagGroup} aria-label="Overflow example">
                        {tabs.map((tab) => (
                            <OverflowItem
                                key={tab.route}
                                id={tab.route}
                                priority={selectedRoute === tab.route ? 2 : 1}
                            >
                                <Tag
                                    size="medium"
                                    className={mergeClasses(
                                        styles.tag,
                                        selectedRoute === tab.route ? styles.activeTag : ''
                                    )}
                                    shape="circular"
                                    onClick={() => onTabSelect(tab.route)}
                                    appearance={selectedRoute === tab.route ? 'outline' : 'filled'}
                                >
                                    {tab.title}
                                </Tag>
                            </OverflowItem>
                        ))}
                        <OverflowMenu onTabSelect={onTabSelect} tabs={tabs} />
                    </TagGroup>
                ) : (
                    <TabList
                        appearance="transparent"
                        selectedValue={selectedRoute}
                        onTabSelect={(_, d) => onTabSelect(d.value as string)}
                    >
                        {tabs.map((tab) => (
                            <OverflowItem
                                key={tab.route}
                                id={tab.route}
                                priority={selectedRoute === tab.route ? 2 : 1}
                            >
                                <Tab value={tab.route}>{tab.title}</Tab>
                            </OverflowItem>
                        ))}
                        <OverflowMenu onTabSelect={onTabSelect} tabs={tabs} />
                    </TabList>
                )}
            </Overflow>
        </div>
    );
};
