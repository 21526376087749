import * as React from 'react';
import { useMessageImageStyles } from './MessageImage.styles';

export interface IMessageImageProps {
    imageUrl?: string;
    imageTextAlt?: string;
}

const MessageImage = ({ imageUrl: url, imageTextAlt }: IMessageImageProps): React.ReactElement => {
    const styles = useMessageImageStyles();

    return !!url ? (
        <div className={styles.imgCtn}>
            <img className={styles.img} src={url} alt={imageTextAlt} />
        </div>
    ) : (
        <></>
    );
};

export default MessageImage;
