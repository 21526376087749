import { makeStyles } from '@configs/theme/v9';

export const useMessageImageStyles = makeStyles({
    imgCtn: { position: 'relative', width: '100%', paddingTop: '100%' },

    img: {
        display: 'block',
        opacity: '1',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
});
