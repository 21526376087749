import { MessageInstanceFullContentModel } from '@models/data/MessageInstanceFullContentModel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Text,
    TextareaOnChangeData,
    TextareaProps,
    Button,
    mergeClasses
} from '@fluentui/react-components';
import { userStyles } from './MessageCommunicationStep.styles';
import { CheckmarkCircle24Regular, DismissCircle24Regular } from '@fluentui/react-icons';
import MessageInteractionActions from '../MessageInteractionActions';
import { useAppDispatch } from '@state';
import { cancelMessageInteraction, confirmCommunication } from '@state/selectedMessage';
import { ConfirmationDialogAction } from '@components/ConfirmationDialogAction';
import { SizeModeType } from '@models/ui/SizeModeType';
import { MessageBanner } from '../MessageBanner';
import DirectReportsList from './DirectReportsList';
import OtherColleaguesSection from './OtherColleaguesSection';
import AnnotationTextSection from './AnnotationTextSection';

export interface IMessageCommunicationStepProps {
    message: MessageInstanceFullContentModel;
    sizeMode: SizeModeType;
}

const MessageCommunicationStep: React.FC<IMessageCommunicationStepProps> = ({
    message,
    sizeMode
}) => {
    const dispatch = useAppDispatch();
    const styles = userStyles();
    const { t } = useTranslation();

    const [otherColleagueIds, setOtherColleagueIds] = React.useState<number[]>([]);
    const [selectedDirectUserIds, setSelectedSubordinateIds] = React.useState<number[]>([]);
    const [annotationText, setAnnotationText] = React.useState('');

    const onAnnotationChange: TextareaProps['onChange'] = React.useCallback(
        (ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
            setAnnotationText(data.value);
        },
        []
    );

    const usersChanged = React.useCallback(
        (): boolean => !!selectedDirectUserIds.length || !!otherColleagueIds.length,
        [otherColleagueIds.length, selectedDirectUserIds.length]
    );

    const onClose = React.useCallback(() => dispatch(cancelMessageInteraction()), [dispatch]);
    const onConfirmMessageCommunication = React.useCallback(async (): Promise<void> => {
        dispatch(
            confirmCommunication({
                annotationText: annotationText,
                userIds: [...selectedDirectUserIds, ...otherColleagueIds]
            })
        );
    }, [annotationText, selectedDirectUserIds, otherColleagueIds, dispatch]);

    return (
        <div className={styles.root}>
            <Text
                className={styles.subTitle}
                as="p"
                title={t('MessageInteraction.CommsStep.StepTitle1') ?? ''}
            >
                {t('MessageInteraction.CommsStep.StepTitle1')}
            </Text>
            <Text
                className={styles.subTitle}
                as="p"
                title={t('MessageInteraction.CommsStep.StepTitle2') ?? ''}
            >
                {t('MessageInteraction.CommsStep.StepTitle2')}
            </Text>
            <Text
                className={styles.subTitle}
                as="p"
                title={t('MessageInteraction.CommsStep.StepTitle3') ?? ''}
            >
                {t('MessageInteraction.CommsStep.StepTitle3')}
            </Text>
            <Text
                className={styles.subTitle}
                as="p"
                title={t('MessageInteraction.CommsStep.StepTitle4') ?? ''}
            >
                {t('MessageInteraction.CommsStep.StepTitle4')}
            </Text>
            <MessageBanner critical={message.critical} />
            <DirectReportsList
                reports={message.directReports}
                className={mergeClasses(styles.section, styles.directReportSection)}
                onChangeSelected={setSelectedSubordinateIds}
                sizeMode={sizeMode}
            />
            <OtherColleaguesSection
                className={styles.section}
                excludeSubordinateIds={message.directReports.map((r) => r.subordinate.id)}
                onSelectionChanged={setOtherColleagueIds}
            />
            <AnnotationTextSection
                className={styles.section}
                onAnnotationChange={onAnnotationChange}
            />

            <MessageInteractionActions fullWidth={sizeMode === SizeModeType.Mobile}>
                <ConfirmationDialogAction
                    dialogTitle={t('ConfirmationDialog.DialogTitle')}
                    dialogTriggerButtonLabel={t(
                        'MessageInteraction.CommsStep.ConfirmCommsBtnLabel'
                    )}
                    dialogTriggerButtonProps={{
                        appearance: 'primary',
                        icon: <CheckmarkCircle24Regular />
                    }}
                    onConfirm={onConfirmMessageCommunication}
                >
                    <Text as="p">{t('ConfirmationDialog.ConfirmMessagePublishText')}</Text>
                </ConfirmationDialogAction>

                {usersChanged() ? (
                    <ConfirmationDialogAction
                        dialogTitle={t('CancelDialog.DialogTitle')}
                        dialogTriggerButtonLabel={t('MessageInteraction.CommsStep.CancelButton')}
                        dialogTriggerButtonProps={{
                            appearance: 'outline',
                            icon: <DismissCircle24Regular />
                        }}
                        onConfirm={onConfirmMessageCommunication}
                    >
                        <Text as="p">
                            {t('ConfirmationDialog.ConfirmCancelationToPublishText')}
                        </Text>
                    </ConfirmationDialogAction>
                ) : (
                    <Button
                        appearance="outline"
                        icon={<DismissCircle24Regular />}
                        size="medium"
                        onClick={onClose}
                    >
                        {t('MessageInteraction.CommsStep.CancelButton')}
                    </Button>
                )}
            </MessageInteractionActions>
        </div>
    );
};

export default MessageCommunicationStep;
