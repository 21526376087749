import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useReportListItemStyles = makeStyles({
    root: {
        '& .fui-Checkbox__indicator': {
            height: '20px',
            width: '20px',
            ...shorthands.margin(tokens.spacingVerticalMNudge, tokens.spacingHorizontalMNudge),
            '& svg': {
                width: '16px',
                height: '16px'
            }
        },
        '& .fui-Persona__primaryText': {
            lineHeight: tokens.lineHeightBase500
        }
    },
    label: {
        display: 'inline-block',
        lineHeight: '28px',
        '& > *': {
            verticalAlign: 'middle'
        }
    }
});
