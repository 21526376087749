import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const useFeedbackDialogStyles = makeStyles({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        color: tokens.colorNeutralForeground3
    },
    field: {
        '>label': {
            color: tokens.colorNeutralForeground3
        }
    },
    btn: { whiteSpace: 'nowrap', height: '32px' },
    iconBtn: {
        whiteSpace: 'nowrap',
        height: '32px',
        width: '36px',
        maxWidth: '36px',
        backgroundColor: tokens.colorNeutralBackground6
    },
    text: {
        ...shorthands.margin(0)
    }
});
