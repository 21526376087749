import { makeStyles, tokens, shorthands, typographyStyles } from '@configs/theme/v9';

export const useGroupSelectStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateRows: 'repeat(1fr)',
        justifyItems: 'start'
    },
    label: {
        ...typographyStyles.subtitle2,
        marginBottom: '12px'
    },
    checkBoxGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginLeft: '0px',
        '>label': {
            paddingLeft: '0px'
        },
        ':hover': {
            color: tokens.colorNeutralForeground1Hover,
            backgroundColor: tokens.colorNeutralBackground1Hover,
            '> .fui-Checkbox__indicator': {
                ...shorthands.borderColor(tokens.colorNeutralForeground1Hover)
            }
        }
    }
});
