import { makeStyles, shorthands, tokens } from '@configs/theme/v9';

export const useMessageFilterTagsStyles = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        zIndex: 0, // stop the browser resize handle from piercing the overflow menu
        height: '100%',
        minWidth: '150px',
        maxWidth: '300px',
        boxSizing: 'content-box',
        justifyContent: 'right',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center'
    },
    tagGroup: {
        display: 'flex' // TagGroup is inline-flex by default, but we want it to be same width as the container
    },
    tag: {
        ':hover': {
            color: tokens.colorCompoundBrandForeground1Hover,
            backgroundColor: tokens.colorBrandBackground2Hover
        }
    }
});

export const useMenuItemStyles = makeStyles({
    menuItem: {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'transparent'
        }
    },
    menuTag: {
        ':hover': {
            color: tokens.colorCompoundBrandForeground1Hover,
            backgroundColor: tokens.colorBrandBackground2Hover
        }
    }
});
