import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const useMessageInteractionsViewStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: '100%',
        width: '100%',
        ...shorthands.overflow('hidden', 'hidden'),
        boxSizing: 'border-box'
    },
    scrollable: {
        position: 'initial',
        width: '100%',
        maxWidth: '100%',
        ...shorthands.overflow('hidden', 'auto'),
        height: 'calc(100% - 0px)'
    },
    inner: {
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: tokens.shadow4Brand,
        maxWidth: '1600px',
        ...shorthands.margin('0', 'auto'),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...shorthands.overflow('hidden', 'hidden'),
        paddingBottom: '20px'
    }
});
