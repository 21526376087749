import { makeStyles, shorthands } from '@configs/theme/v9';

export const useMessageInteractionActionsStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        ...shorthands.gap('12px'),
        flexDirection: 'row',
        boxSizing: 'border-box'
    },
    fullWidth: {
        '& button': {
            minWidth: '100%',
            maxWidth: '100%'
        }
    }
});
