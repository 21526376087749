import { makeStyles, typographyStyles } from '@configs/theme/v9';

export const useDropdownStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateRows: 'repeat(1fr)',
        justifyItems: 'start',
        maxWidth: '100%',
        width: '100%'
    },
    label: {
        ...typographyStyles.subtitle2,
        marginBottom: '12px'
    },
    dropdown: {
        maxWidth: '100%',
        display: 'block',
        width: '100%'
    }
});
