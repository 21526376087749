import * as React from 'react';
import { MessagesViewType } from '@models/ui/MessagesViewType';
import { useIconStyles } from './MessageViewSwitcher.styles';
import {
    AppsList24Filled,
    Grid24Filled,
    AppsList24Regular,
    Grid24Regular
} from '@fluentui/react-icons';
import { Button, mergeClasses } from '@fluentui/react-components';

export interface IViewSwitcherProps {
    active: MessagesViewType;
    onViewChange: (view: MessagesViewType) => void;
}

const ViewSwitcher = ({ active, onViewChange }: IViewSwitcherProps): React.ReactElement => {
    const styles = useIconStyles();

    const handleSwitchView = (newView: MessagesViewType): void => {
        onViewChange(newView);
    };

    return (
        <div className={styles.root}>
            <Button
                appearance="transparent"
                className={mergeClasses(
                    styles.icon,
                    active === MessagesViewType.Tiles ? styles.active : ''
                )}
                onClick={() => handleSwitchView(MessagesViewType.Tiles)}
                icon={active === MessagesViewType.Tiles ? <Grid24Filled /> : <Grid24Regular />}
            />
            <Button
                appearance="transparent"
                className={mergeClasses(
                    styles.icon,
                    active === MessagesViewType.List ? styles.active : ''
                )}
                onClick={() => handleSwitchView(MessagesViewType.List)}
                icon={
                    active === MessagesViewType.List ? <AppsList24Filled /> : <AppsList24Regular />
                }
            />
        </div>
    );
};

export default ViewSwitcher;
