import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const useIconStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'nowrap',
        ...shorthands.gap('4px')
    },
    icon: {
        display: 'block',
        width: '32px',
        height: '32px',
        cursor: 'pointer',
        fill: tokens.colorBrandForegroundLink,
        fontSize: '20px'
    },

    active: {
        color: tokens.colorBrandForegroundLinkHover
    }
});
