import * as React from 'react';

import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Text,
    mergeClasses
} from '@fluentui/react-components';
import moment from 'moment';

import { Warning24Filled } from '@fluentui/react-icons';
import { ColumnsIds, useTableConfiguration } from './adminListView.config';
import { parseCriticalToImportance } from '@configs/importanceLevels';
import { useTranslation } from 'react-i18next';
import { useListViewStyles } from './../MessageListView.styles';
import { MessageInstanceAdminBasicData } from '@api/models/MessageInstanceAdminBasicData';
import { getMessageStatusesMap } from '@configs/messageStatuses';

export interface IMesageListViewProps {
    items: MessageInstanceAdminBasicData[];
    onClick: (item: MessageInstanceAdminBasicData) => void;
    compact?: boolean;
}

export const MessageAdminListView = ({
    items,
    compact,
    onClick
}: IMesageListViewProps): React.ReactElement => {
    const { t } = useTranslation();
    const { headerSortProps, rows } = useTableConfiguration(items);
    const styles = useListViewStyles();

    const statuses = getMessageStatusesMap(t);

    return (
        <div className={!compact ? styles.root : styles.rootCompact}>
            <Table size="medium" className={styles.table}>
                <TableHeader className={styles.header}>
                    <TableRow>
                        <TableHeaderCell className={styles.firstColumn} />
                        <TableHeaderCell {...headerSortProps(ColumnsIds.leaderColumnId)}>
                            {t('Common.MessageListView.MessageLeaderHeader')}
                        </TableHeaderCell>
                        <TableHeaderCell {...headerSortProps(ColumnsIds.titleColumnId)}>
                            {t('Common.MessageListView.MessageTitleHeader')}
                        </TableHeaderCell>
                        <TableHeaderCell {...headerSortProps(ColumnsIds.statusColumnId)}>
                            {t('Common.MessageListView.StatusHeader')}
                        </TableHeaderCell>
                        <TableHeaderCell {...headerSortProps(ColumnsIds.importanceLevelColumnId)}>
                            {t('Common.MessageListView.ImportanceLevelHeader')}
                        </TableHeaderCell>
                        <TableHeaderCell
                            {...headerSortProps(ColumnsIds.messagePublishingDateUTCColumnId)}
                        >
                            {t('Common.MessageListView.CreationDateHeader')}
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows.map((c) => (
                        <TableRow
                            key={c.item.id}
                            onClick={() => onClick(c.item)}
                            className={mergeClasses(
                                styles.row,
                                c.item.critical ? styles.critical : ''
                            )}
                        >
                            <TableCell>
                                <TableCellLayout>
                                    <Text as="span" className={styles.cellText}>
                                        {c.item.critical && (
                                            <Warning24Filled className={styles.titleIcon} />
                                        )}
                                    </Text>
                                </TableCellLayout>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className={styles.cellText}>
                                    {c.item.leader.name}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className={styles.cellText}>
                                    {c.item.title}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className={styles.cellText}>
                                    {statuses[c.item.status]}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className={styles.cellText}>
                                    {parseCriticalToImportance(c.item.critical, t).title}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <Text as="span" className={styles.cellText}>
                                    {moment(c.item.messagePublishingDateUTC).format('DD/MM/yyyy')}
                                </Text>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default MessageAdminListView;
