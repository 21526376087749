import * as React from 'react';
import { useAppDispatch } from '@state';
import { useTranslation } from 'react-i18next';
import { cancelMessageInteraction } from '@state/selectedMessage';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { Button } from '@fluentui/react-components';
import { useCloseButtonStyles } from './CloseButton.styles';

export const CloseButton = (): React.ReactElement => {
    const styles = useCloseButtonStyles();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <div className={styles.closeBtnCtn}>
            <Button
                appearance="transparent"
                size="small"
                aria-label={t('MessageViewHeader.CloseAriaLabel') || ''}
                title={t('MessageViewHeader.CloseLabel') || ''}
                icon={<Dismiss24Regular />}
                className={styles.headerCloseBtn}
                onClick={() => dispatch(cancelMessageInteraction())}
            />
        </div>
    );
};
