import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { MessageCategoryData, MessagePeriodData, MessageStrategicFocusData } from '@api/models';
import {
    getLinks,
    getMessageCategories,
    getMessagePeriods,
    getMessageStrategicFocuses
} from '@api/services/meta';
import { AppLinks } from '@api/models/AppLinks';

/* Models */
export interface IMetaState {
    categories: MessageCategoryData[];
    strategicFocuses: MessageStrategicFocusData[];
    periods: MessagePeriodData[];
    loaded: boolean;
    links: AppLinks | undefined;
}

const initialState: IMetaState = {
    categories: [],
    strategicFocuses: [],
    periods: [],
    loaded: false,
    links: undefined
};

/* Thunks */
type AllMeta = {
    categories: MessageCategoryData[];
    strategicFocuses: MessageStrategicFocusData[];
    periods: MessagePeriodData[];
    links: AppLinks | undefined;
};

const loadMeta = createAsyncThunk<AllMeta, void, { state: RootState }>(
    'loadMeta',
    async (_, api) => {
        const meta = api.getState().meta;

        if (meta.loaded) {
            return meta;
        }

        const [categories, strategicFocuses, periods, links] = await Promise.all([
            getMessageCategories(),
            getMessageStrategicFocuses(),
            getMessagePeriods(),
            getLinks()
        ]);

        return { categories, strategicFocuses, periods, links };
    }
);

/** Slice */
export const metaSlice = createSlice({
    name: 'meta',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadMeta.fulfilled, (state, action) => {
            state.categories = action.payload.categories;
            state.strategicFocuses = action.payload.strategicFocuses;
            state.periods = action.payload.periods;
            state.links = action.payload.links;
            state.loaded = true;
        });
    }
});

/** Selectors */
export { loadMeta };

export default metaSlice.reducer;
