import Loader from '@components/controls/common/Loader';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface IProcessingLoaderProps {
    processingType: 'confirmation' | 'irrelevant' | 'loading' | 'none';
}

export const MessageLoader = ({ processingType }: IProcessingLoaderProps): React.ReactElement => {
    let txt = '';
    const { t } = useTranslation();

    if (processingType === 'none') {
        return <></>;
    }

    switch (processingType) {
        case 'confirmation': {
            txt = t('MessageInteraction.CommunicationOperationMessage');
            break;
        }
        case 'irrelevant': {
            txt = t('MessageInteraction.IrrelevantOperationMessage');
            break;
        }
        default: {
            txt = t('MessageInteraction.LoadingOperationMessage');
            break;
        }
    }

    return <Loader message={txt} />;
};
