import { Text } from '@fluentui/react-components';
import { useMessageTitleStyles } from './MessageTitle.styles';
import * as React from 'react';

export interface IMessageTitleProps {
    title: string;
}

export const MessageTitle = ({ title }: IMessageTitleProps): React.ReactElement => {
    const styles = useMessageTitleStyles();

    return (
        <Text as="h3" size={500} className={styles.messageTitle}>
            {title}
        </Text>
    );
};
