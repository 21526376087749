import * as React from 'react';
import { Button, FluentProvider, Text, mergeClasses } from '@fluentui/react-components';
import { Panel } from '@fluentui/react';
import { useAppDispatch, useStateSelector } from '@state';
import { applyMessageFilters, setPanelVisibility, setLeaderFilter } from '@state/filters';
import { useFiltersPanelStyles } from './MessageFiltersPanel.styles';
import { useTranslation } from 'react-i18next';
import GroupSelect from '@components/controls/common/GroupSelect';
import { Filter16Filled, Dismiss24Regular } from '@fluentui/react-icons';
import { useAppV8Theme } from '@configs/theme/v8';
import { useAppV9Theme } from '@configs/theme/v9';
import { getImportanceLevels } from '@configs/importanceLevels';
import DropDownPicker from '@components/controls/common/Dropdown';
import { getPeriodsFilterConfig } from '@configs/predefinedPeriods';
import { PeoplePicker } from '@components/controls/common/PeoplePicker';
import { searchLeaders } from '@api/services/users';
import { getMessageStatuses } from '@configs/messageStatuses';

export interface IMessageFiltersPanelActionProps {
    applyFiltersCallback(): void;
    adminMode?: boolean;
    iconOnlyButton?: boolean;
}

export const MessageFiltersPanelAction = ({
    applyFiltersCallback,
    adminMode,
    iconOnlyButton
}: IMessageFiltersPanelActionProps): React.ReactElement => {
    const { t } = useTranslation();
    const styles = useFiltersPanelStyles();
    const themeV8 = useAppV8Theme();
    const themeV9 = useAppV9Theme();

    const importanceLevels = React.useMemo(() => getImportanceLevels(t), [t]);
    const messageStatuses = React.useMemo(() => getMessageStatuses(t), [t]);

    const dispatch = useAppDispatch();
    const {
        messageFilters: appliedFilters,
        filteredLeader,
        showFiltersPanel: showPanel
    } = useStateSelector((x) => x.filters);

    const {
        categories: allCategories,
        strategicFocuses: allStrategicFocuses,
        periods: allPeriods
    } = useStateSelector((x) => x.meta);

    const [periodGroups] = React.useState(getPeriodsFilterConfig(t, allPeriods));

    const [filtersConfig, setFiltersConfig] = React.useState(appliedFilters);
    const [selectedLeader, setSelectedLeader] = React.useState(filteredLeader);

    const closePanel: () => void = React.useCallback(() => {
        setFiltersConfig(appliedFilters);
        if (adminMode) {
            setSelectedLeader(filteredLeader);
        }
        dispatch(setPanelVisibility(false));
    }, [dispatch, adminMode, appliedFilters, filteredLeader]);

    const applyChanges: () => void = React.useCallback(() => {
        dispatch(applyMessageFilters(filtersConfig));
        dispatch(setLeaderFilter(selectedLeader));
        applyFiltersCallback();
    }, [filtersConfig, dispatch, applyFiltersCallback, selectedLeader]);

    const cleanFilters = React.useCallback(() => {
        setFiltersConfig({
            categories: [],
            strategicFocuses: [],
            importanceLevels: [],
            selectedPeriod: undefined,
            statuses: []
        });
        if (adminMode) {
            setSelectedLeader(undefined);
        }
    }, [adminMode]);

    const onRenderFooterContent = React.useCallback(
        () => (
            <FluentProvider theme={themeV9}>
                <div className={styles.footer}>
                    <div>
                        <Button
                            appearance="subtle"
                            size="small"
                            aria-label={t('Common.FiltersPanel.ClearAllLabel') || ''}
                            title={t('Common.FiltersPanel.ClearAllLabel') || ''}
                            onClick={cleanFilters}
                        >
                            {t('Common.FiltersPanel.ClearAllLabel')}
                        </Button>
                    </div>
                    <div className={styles.rightActions}>
                        <Button appearance="secondary" size="small" onClick={closePanel}>
                            {t('Common.FiltersPanel.CancelLabel')}
                        </Button>
                        <Button
                            appearance="primary"
                            size="small"
                            aria-label={t('Common.FiltersPanel.ApplyLabel') || ''}
                            title={t('Common.FiltersPanel.ApplyLabel') || ''}
                            onClick={applyChanges}
                        >
                            {t('Common.FiltersPanel.ApplyLabel')}
                        </Button>
                    </div>
                </div>
            </FluentProvider>
        ),
        [themeV9, applyChanges, closePanel, cleanFilters, t, styles.footer, styles.rightActions]
    );

    const onRenderHeader = React.useCallback(
        () => (
            <FluentProvider theme={themeV9}>
                <div className={styles.header}>
                    <Text as="h3" size={400} className={styles.headerText}>
                        {t('Common.FiltersPanel.Label')}
                    </Text>
                    <Button
                        appearance="transparent"
                        size="small"
                        aria-label={t('Common.FiltersPanel.CancelLabel') || ''}
                        title={t('Common.FiltersPanel.CancelLabel') || ''}
                        icon={<Dismiss24Regular />}
                        className={styles.headerCloseBtn}
                        onClick={() => dispatch(setPanelVisibility(false))}
                    />
                </div>
            </FluentProvider>
        ),
        [themeV9, dispatch, t, styles.header, styles.headerText, styles.headerCloseBtn]
    );

    React.useEffect(() => {
        setFiltersConfig(appliedFilters);
    }, [appliedFilters]);

    React.useEffect(() => {
        setSelectedLeader(filteredLeader);
    }, [filteredLeader]);

    const anyFiltered =
        appliedFilters.categories?.length > 0 ||
        appliedFilters.strategicFocuses?.length > 0 ||
        !!appliedFilters.selectedPeriod ||
        appliedFilters.importanceLevels.length > 0 ||
        (!!adminMode && !!filteredLeader);

    return (
        <>
            <div>
                <Button
                    appearance={anyFiltered ? 'primary' : 'outline'}
                    size="medium"
                    aria-label={t('Common.FiltersPanel.FilterButtonLabel') || ''}
                    title={t('Common.FiltersPanel.FilterButtonLabel') || ''}
                    icon={<Filter16Filled />}
                    iconPosition="before"
                    className={mergeClasses(
                        anyFiltered ? '' : styles.filterButton,
                        iconOnlyButton ? styles.iconOnlyBtn : ''
                    )}
                    onClick={() => dispatch(setPanelVisibility(true))}
                >
                    {!iconOnlyButton ? t('Common.FiltersPanel.FilterButtonLabel') || '' : undefined}
                </Button>
                <Panel
                    isOpen={showPanel}
                    isFooterAtBottom={true}
                    hasCloseButton={false}
                    onRenderFooterContent={onRenderFooterContent}
                    onRenderHeader={onRenderHeader}
                    styles={{
                        main: styles.panel
                    }}
                    theme={themeV8}
                >
                    <FluentProvider theme={themeV9}>
                        <div className={styles.root}>
                            {adminMode && (
                                <PeoplePicker
                                    onSearchUsers={searchLeaders}
                                    onSelectUser={setSelectedLeader}
                                    label={t('Common.FiltersPanel.LeaderLabel') || ''}
                                    placeholder={t('Common.FiltersPanel.LeaderPlaceholder') || ''}
                                    selectedUser={selectedLeader}
                                    noResultText={t('Common.FiltersPanel.LeaderNoResults') || ''}
                                />
                            )}
                            <DropDownPicker
                                ariaLabel={t('Common.FiltersPanel.PeriodArialabel') || ''}
                                placeholder={t('Common.FiltersPanel.PeriodPlaceholder') || ''}
                                onChanged={(selected) =>
                                    setFiltersConfig({
                                        ...filtersConfig,
                                        selectedPeriod: selected
                                    })
                                }
                                optionGroups={periodGroups}
                                selectedValue={filtersConfig.selectedPeriod}
                                label={t('Common.FiltersPanel.PeriodLabel') || ''}
                            />
                            {adminMode && (
                                <GroupSelect
                                    onChanged={(selected) =>
                                        setFiltersConfig({
                                            ...filtersConfig,
                                            statuses: selected
                                        })
                                    }
                                    options={messageStatuses}
                                    key={'messageStatuses'}
                                    label={t('Common.FiltersPanel.StatusesLabel') || ''}
                                    selectedItems={filtersConfig.statuses}
                                    showDivider={true}
                                />
                            )}
                            <GroupSelect
                                onChanged={(selected) =>
                                    setFiltersConfig({
                                        ...filtersConfig,
                                        categories: selected
                                    })
                                }
                                options={allCategories}
                                key={'categories'}
                                label={t('Common.FiltersPanel.CategoriesLabel') || ''}
                                selectedItems={filtersConfig.categories}
                                showDivider={true}
                            />
                            <GroupSelect
                                onChanged={(selected) =>
                                    setFiltersConfig({
                                        ...filtersConfig,
                                        strategicFocuses: selected
                                    })
                                }
                                options={allStrategicFocuses}
                                key={'StrategicFocuses'}
                                label={t('Common.FiltersPanel.StrategicFocus') || ''}
                                selectedItems={filtersConfig.strategicFocuses}
                                showDivider={true}
                            />
                            <GroupSelect
                                onChanged={(selected) =>
                                    setFiltersConfig({
                                        ...filtersConfig,
                                        importanceLevels: selected
                                    })
                                }
                                options={importanceLevels}
                                key={'importanceLevels'}
                                label={t('Common.FiltersPanel.ImportanceLevelsLabel') || ''}
                                selectedItems={filtersConfig.importanceLevels}
                                showDivider={false}
                            />
                        </div>
                    </FluentProvider>
                </Panel>
            </div>
        </>
    );
};
