import {
    Button,
    ButtonProps,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger
} from '@fluentui/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirmationDialogActionStyles } from './ConfirmationDialogAction.styles';

interface IConfirmationDialogButtonProps extends React.PropsWithChildren {
    dialogTriggerButtonProps?: ButtonProps;
    dialogTriggerButtonLabel?: string | null;
    dialogTitle: string;
    isConfirmReady?: () => boolean;
    onConfirm?: () => void;
}

export const ConfirmationDialogAction: React.FC<IConfirmationDialogButtonProps> = ({
    dialogTriggerButtonProps,
    dialogTriggerButtonLabel,
    dialogTitle,
    isConfirmReady,
    onConfirm,
    children
}) => {
    const styles = useConfirmationDialogActionStyles();
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    return (
        <Dialog modalType="modal" open={open} onOpenChange={(_, data) => setOpen(data.open)}>
            <DialogTrigger disableButtonEnhancement>
                <Button appearance="primary" size="medium" {...dialogTriggerButtonProps}>
                    {dialogTriggerButtonLabel}
                </Button>
            </DialogTrigger>
            <DialogSurface role="form">
                <DialogBody>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent className={styles.dialogContent}>{children}</DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                type="submit"
                                appearance="primary"
                                onClick={onConfirm}
                                disabled={isConfirmReady ? !isConfirmReady() : false}
                            >
                                {t('Common.ConfirmationDialog.ConfirmButton')}
                            </Button>
                        </DialogTrigger>

                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">
                                {t('Common.ConfirmationDialog.CancelButton')}
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
