import { makeStyles, shorthands } from '@configs/theme/v9';

export const useMessageMetaStyles = makeStyles({
    messageMeta: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        flexWrap: 'nowrap',
        ...shorthands.gap('20px'),
        boxSizing: 'border-box',
        alignItems: 'center'
    },

    flexWrapItem: {
        flexWrap: 'wrap'
    },
    flexibleWidth: {
        width: 'auto',
        flexWrap: 'wrap'
    }
});
