import { makeStyles, shorthands, typographyStyles } from '@configs/theme/v9';

export const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateRows: 'repeat(1fr)',
        justifyItems: 'start',
        maxWidth: '400px',
        width: '100%'
    },
    field: {
        display: 'grid',
        justifyItems: 'start',
        ...shorthands.gap('2px')
    },
    combobox: {
        width: '100%',
        maxWidth: '100%'
    },
    label: {
        ...typographyStyles.subtitle2,
        marginBottom: '12px'
    },
    option: {
        maxWidth: '100%',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'unset',
        '>span:not(.fui-Avatar)': {
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%'
        }
    },
    avatar: {
        width: '32px'
    },
    hidden: {
        display: 'none'
    },
    noResults: {
        width: '100%'
    },
    selected: {
        maxWidth: '100%',
        width: '100%'
    },
    selectedFullWidth: {
        maxWidth: '100%',
        width: 'auto'
    }
});
