import { makeStyles, tokens, shorthands, typographyStyles } from '@configs/theme/v9';

export const useMessageBannerStyles = makeStyles({
    critical: {
        display: 'flex',
        alignItems: 'center',
        color: tokens.criticalMessageColor,
        backgroundColor: tokens.criticalCustomBackground,
        ...shorthands.borderRadius('4px'),
        ...shorthands.border('1px', 'solid', tokens.colorPaletteMarigoldBorderActive),
        ...typographyStyles.subtitle2,
        fontWeight: 600,
        ...shorthands.padding('12px', '16px'),
        '&>span': {
            ...shorthands.padding('0', '8px'),
            fontWeight: '600'
        }
    }
});
