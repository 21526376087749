import { MessageInstanceStatus } from '@api/models';
import { TitleValuePair } from '../models/data/TitleValuePair';

export const getMessageStatuses = (
    translation: (key: string) => string
): TitleValuePair<number>[] => [
    {
        id: MessageInstanceStatus.NotStarted,
        title: translation('MessageStatus.NotStarted')
    },
    {
        id: MessageInstanceStatus.InProgress,
        title: translation('MessageStatus.InProgress')
    },
    {
        id: MessageInstanceStatus.Completed,
        title: translation('MessageStatus.Completed')
    }
];

export const getMessageStatusesMap = (
    translation: (key: string) => string
): { [x: number]: string } => {
    return {
        [MessageInstanceStatus.NotStarted]: translation('MessageStatus.NotStarted'),
        [MessageInstanceStatus.InProgress]: translation('MessageStatus.InProgress'),
        [MessageInstanceStatus.Completed]: translation('MessageStatus.Completed')
    };
};
