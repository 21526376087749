import { MessageInstanceFullContentModel } from '@models/data/MessageInstanceFullContentModel';
import * as React from 'react';
import { useLeaderAnnotationStyles } from './LeaderAnnotation.styles';
import { Text, mergeClasses } from '@fluentui/react-components';
import UserInfo from '@components/UserInfo';

export interface ILeaderAnnotationProps {
    message: MessageInstanceFullContentModel;
}

export const LeaderAnnotation = ({ message }: ILeaderAnnotationProps): React.ReactElement => {
    const styles = useLeaderAnnotationStyles();
    return !!message.annotationText && message.cascadedBy?.aadId ? (
        <section className={mergeClasses(styles.annotationSection)}>
            <Text as="p" className={styles.annotationHeader}>
                <UserInfo
                    className={styles.userInfo}
                    userId={message.cascadedBy?.aadId}
                    name={message.cascadedBy?.name}
                    size="small"
                />
                <Text as="span" className={styles.text}>
                    added the following:
                </Text>
            </Text>
            <div
                className={styles.annotationText}
                dangerouslySetInnerHTML={{ __html: message.annotationText }}
            ></div>
        </section>
    ) : (
        <></>
    );
};
