import React from 'react';
import { DirectReportWithUserDataModel } from '@api/models/DirectReportWithUserDataModel';
import { Checkbox, CheckboxOnChangeData } from '@fluentui/react-components';
import UserInfo from '@components/UserInfo';
import { useTranslation } from 'react-i18next';
import { useReportListItemStyles } from './DirectReportItem.styles';

export interface IReportListItemProps {
    report: DirectReportWithUserDataModel;
    selected: boolean;
    userPhotoUri?: string;
    actionTakenByPhotoUri?: string;
    onSelectionChanged?: (subordinateId: number, checked: boolean) => void;
}

const ReportListItem: React.FC<IReportListItemProps> = ({
    report,
    selected,
    userPhotoUri,
    actionTakenByPhotoUri,
    onSelectionChanged: onChangeSelection
}) => {
    const { t } = useTranslation();
    const styles = useReportListItemStyles();

    const reportUserName = `${report.subordinate.name}${
        report.isLeader ? ` ${t('MessageInteraction.CommsStep.PeaopleLeader')}` : ''
    }`;

    const onChange = React.useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
            if (onChangeSelection) {
                onChangeSelection(report.subordinate.id, data.checked === true);
            }
        },
        [report.subordinate.id, onChangeSelection]
    );

    return (
        <Checkbox
            className={styles.root}
            checked={selected}
            onChange={onChange}
            disabled={report.completed || !!report.actionTakenBy}
            label={
                <div className={styles.label}>
                    <UserInfo photoDataUri={userPhotoUri} name={reportUserName} size="small" />
                    {report.actionTakenBy && (
                        <>
                            <span>{` — ${t(
                                'MessageInteraction.CommsStep.HasAlreadyBeenCommunicatedBy'
                            )}`}</span>
                            <UserInfo
                                style={{ paddingLeft: '12px' }}
                                photoDataUri={actionTakenByPhotoUri}
                                name={report.actionTakenBy.name}
                                size="small"
                            />
                        </>
                    )}
                </div>
            }
        />
    );
};

export default ReportListItem;
