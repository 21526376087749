import { Checkbox, CheckboxOnChangeData, Divider, Label } from '@fluentui/react-components';
import * as React from 'react';
import { useGroupSelectStyles } from './GroupSelect.styles';
import { TitleValuePair } from '@models/data/TitleValuePair';

export interface IGroupSelectProps {
    options: TitleValuePair<number>[];
    selectedItems?: TitleValuePair<number>[];
    showDivider?: boolean;
    label?: string;
    onChanged: (selected: TitleValuePair<number>[]) => void;
}

const GroupSelect = ({
    label,
    options,
    selectedItems,
    onChanged,
    showDivider
}: IGroupSelectProps): React.ReactElement => {
    const styles = useGroupSelectStyles();

    const selectedIds = selectedItems?.map((i) => i.id) || [];

    const onChange = (
        _ev: React.ChangeEvent<HTMLInputElement>,
        data: CheckboxOnChangeData,
        item: TitleValuePair<number>
    ): void => {
        const value = data.checked as boolean;
        if (value) {
            if (selectedIds.findIndex((i) => i === item.id) < 0) {
                onChanged([...(selectedItems || []), item]);
            }
        } else {
            const idx = selectedIds.findIndex((i) => i === item.id);
            if (idx >= 0) {
                onChanged((selectedItems || []).filter((c) => c.id !== item.id));
            }
        }
    };

    return (
        <div className={styles.root}>
            {!!label && (
                <Label size="large" className={styles.label} title={label}>
                    {label}
                </Label>
            )}
            {options.map((option) => (
                <Checkbox
                    key={option.id}
                    checked={selectedIds.findIndex((x) => x === option.id) !== -1}
                    onChange={(e, v) => onChange(e, v, option)}
                    label={option.title}
                    labelPosition="before"
                    as="input"
                    className={styles.checkBoxGrid}
                />
            ))}
            {!!showDivider && <Divider />}
        </div>
    );
};

export default GroupSelect;
