import { makeStyles } from '@fluentui/react-components';

export const useLayoutStyles = makeStyles({
    container: {
        height: 'inherit',
        overflowY: 'hidden'
    },
    // navWrapper: {
    //     height: '45px'
    // },
    page: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        height: '100%'
    }
});
