import { MessageInstanceLeaderStatus } from '@api/models';
import { TitleValuePair } from '../models/data/TitleValuePair';

export const getMessageLeaderStatuses = (
    translation: (key: string) => string
): TitleValuePair<number>[] => [
    {
        id: MessageInstanceLeaderStatus.Cascaded,
        title: translation('MessageInstanceLeaderStatus.Cascaded')
    },
    {
        id: MessageInstanceLeaderStatus.CascadedByLeader,
        title: translation('MessageInstanceLeaderStatus.CascadedByLeader')
    },
    {
        id: MessageInstanceLeaderStatus.Irrelevant,
        title: translation('MessageInstanceLeaderStatus.Irrelevant')
    }
];

export const getMessageLeaderStatusesMap = (
    translation: (key: string) => string
): { [x: number]: string } => {
    return {
        [MessageInstanceLeaderStatus.Cascaded]: translation('MessageInstanceLeaderStatus.Cascaded'),
        [MessageInstanceLeaderStatus.CascadedByLeader]: translation(
            'MessageInstanceLeaderStatus.CascadedByLeader'
        ),
        [MessageInstanceLeaderStatus.Irrelevant]: translation(
            'MessageInstanceLeaderStatus.Irrelevant'
        )
    };
};
