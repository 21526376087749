import * as React from 'react';
import {
    TagGroup,
    Overflow,
    OverflowItem,
    mergeClasses,
    InteractionTag,
    Menu,
    MenuTrigger,
    InteractionTagPrimary,
    useOverflowMenu,
    MenuPopover,
    MenuList,
    MenuItem,
    useIsOverflowItemVisible,
    Tag
} from '@fluentui/react-components';
import { useMenuItemStyles, useMessageFilterTagsStyles } from './MessageFilterTags.styles';

import { applyMessageFilters, setLeaderFilter } from '@state/filters';
import { useAppDispatch, useStateSelector } from '@state';
import { useTranslation } from 'react-i18next';

type TagData = { id: string; title: string; onRemoveFilter: () => void };

interface ITagProps {
    tag: TagData;
    removeAriaLabel: string;
}

const OverflowMenuItem = ({ tag, removeAriaLabel }: ITagProps): React.ReactElement | null => {
    const isVisible = useIsOverflowItemVisible(tag.id!);

    const styles = useMenuItemStyles();

    if (isVisible) {
        return null;
    }

    return (
        <MenuItem key={tag.id} className={styles.menuItem}>
            <Tag
                size="small"
                className={styles.menuTag}
                shape="circular"
                dismissible
                dismissIcon={{
                    'aria-label': removeAriaLabel,
                    onClick: tag.onRemoveFilter
                }}
                appearance="brand"
            >
                {tag.title}
            </Tag>
            {/* <InteractionTag
                size="small"
                className={styles.menuTag}
                shape="circular"
                appearance="brand"
            >
                <InteractionTagPrimary hasSecondaryAction>{tag.title}</InteractionTagPrimary>
                <InteractionTagSecondary
                    aria-label={removeAriaLabel}
                    onClick={tag.onRemoveFilter}
                />
            </InteractionTag> */}
        </MenuItem>
    );
};

export interface ITagsProps {
    tags: TagData[];
    removeAriaLabel: string;
}

const UnvisibleTagsMenu = ({ tags, removeAriaLabel }: ITagsProps): React.ReactElement | null => {
    const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <InteractionTag size="small" shape="circular" appearance="brand">
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <InteractionTagPrimary ref={ref} aria-label={`${overflowCount} more tags`}>
                        {`+${overflowCount}`}
                    </InteractionTagPrimary>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {tags.map((item) => (
                            <OverflowMenuItem
                                key={item.id}
                                tag={item}
                                removeAriaLabel={removeAriaLabel}
                            />
                        ))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </InteractionTag>
    );
};

export interface IMessageFilterTagsProps {
    changeFiltersCallback: () => void;
}

const MessageFilterTags = ({
    changeFiltersCallback
}: IMessageFilterTagsProps): React.ReactElement => {
    const styles = useMessageFilterTagsStyles();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { messageFilters, filteredLeader } = useStateSelector((c) => c.filters);

    const onCatDismiss = React.useCallback(
        (id: number) => {
            dispatch(
                applyMessageFilters({
                    ...messageFilters,
                    categories: messageFilters.categories.filter((c) => c.id !== id)
                })
            );
            changeFiltersCallback();
        },
        [dispatch, messageFilters, changeFiltersCallback]
    );

    const onImpLevelDismiss = React.useCallback(
        (id: number) => {
            dispatch(
                applyMessageFilters({
                    ...messageFilters,
                    importanceLevels: messageFilters.importanceLevels.filter((c) => c.id !== id)
                })
            );
            changeFiltersCallback();
        },
        [dispatch, messageFilters, changeFiltersCallback]
    );

    const onStrFocusDismiss = React.useCallback(
        (id: number) => {
            dispatch(
                applyMessageFilters({
                    ...messageFilters,
                    strategicFocuses: messageFilters.strategicFocuses.filter((c) => c.id !== id)
                })
            );
            changeFiltersCallback();
        },
        [dispatch, messageFilters, changeFiltersCallback]
    );

    const onPeriodDismiss = React.useCallback(() => {
        dispatch(
            applyMessageFilters({
                ...messageFilters,
                selectedPeriod: undefined
            })
        );
        changeFiltersCallback();
    }, [dispatch, messageFilters, changeFiltersCallback]);

    const onLeaderDismiss = React.useCallback(() => {
        dispatch(setLeaderFilter(undefined));
        changeFiltersCallback();
    }, [dispatch, changeFiltersCallback]);

    const allTags: { id: string; title: string; onRemoveFilter: () => void; className?: string }[] =
        [];

    if (filteredLeader) {
        allTags.push({
            id: `leader_${filteredLeader.id}`,
            onRemoveFilter: onLeaderDismiss,
            title: filteredLeader.name
        });
    }

    if (messageFilters.selectedPeriod) {
        allTags.push({
            id: `period-${messageFilters.selectedPeriod.id}`,
            onRemoveFilter: onPeriodDismiss,
            title: messageFilters.selectedPeriod.title
        });
    }

    const catTags = messageFilters.categories.map((c) => ({
        id: `cat_${c.id}`,
        onRemoveFilter: () => onCatDismiss(c.id),
        title: c.title
    }));

    allTags.push(...catTags);

    const importanceLevelsTags = messageFilters.importanceLevels.map((c) => ({
        id: `imp_${c.id}`,
        onRemoveFilter: () => onImpLevelDismiss(c.id),
        title: c.title
    }));

    allTags.push(...importanceLevelsTags);

    const strFocusTags = messageFilters.strategicFocuses.map((c) => ({
        id: `str_${c.id}`,
        onRemoveFilter: () => onStrFocusDismiss(c.id),
        title: c.title
    }));
    allTags.push(...strFocusTags);

    return (
        <Overflow key={allTags.length} minimumVisible={1}>
            <TagGroup
                className={mergeClasses(styles.tagGroup, styles.container)}
                aria-label="Overflow example"
            >
                {allTags.map((item) => (
                    <OverflowItem key={item.id} id={item.id}>
                        {/* <InteractionTag
                            size="small"
                            className={item.className}
                            shape="circular"
                            appearance="brand"
                        >
                            <InteractionTagPrimary hasSecondaryAction>
                                {item.title}
                            </InteractionTagPrimary>
                            <InteractionTagSecondary
                                aria-label={t('Common.ActionsPanel.Tag.RemoveAriaLabel') || ''}
                                onClick={item.onRemoveFilter}
                            />
                        </InteractionTag> */}
                        <Tag
                            size="small"
                            className={styles.tag}
                            shape="circular"
                            dismissible
                            dismissIcon={{
                                'aria-label': t('Common.ActionsPanel.Tag.RemoveAriaLabel') || '',
                                onClick: item.onRemoveFilter
                            }}
                            appearance="brand"
                        >
                            {item.title}
                        </Tag>
                    </OverflowItem>
                ))}
                <UnvisibleTagsMenu
                    tags={allTags}
                    removeAriaLabel={t('Common.ActionsPanel.Tag.RemoveAriaLabel') || ''}
                />
            </TagGroup>
        </Overflow>
    );
};

export default MessageFilterTags;
