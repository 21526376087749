import { makeStyles, tokens, shorthands } from '@configs/theme/v9';
export const useFullActionsBarStyles = makeStyles({
    ///NAVIGATION
    navigationZonesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        boxSizing: 'border-box',
        ...shorthands.padding('0px', '20px'),
        height: '48px'
    },
    navigationMainZoneContainer: {
        marginLeft: '-10px',
        flexGrow: '1'
    },

    ///ZONES Wrapper
    zonesContainer: {
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
        boxShadow: tokens.customShadow2
    },
    ///MAIN ZONE
    mainZoneContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        columnGap: '2px',
        alignItems: 'center',
        boxSizing: 'border-box',
        backgroundColor: tokens.colorNeutralBackground1,
        height: '48px',
        ...shorthands.padding('0px', '20px'),
        boxShadow: tokens.customShadow1
    },
    mainZoneMessage: {
        fontWeight: 600,
        color: tokens.colorBrandForeground1
    },
    mainZoneMessageContainer: {
        display: 'flex',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        ...shorthands.flex(1)
    },

    mainZoneActionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        ...shorthands.gap('12px'),
        ...shorthands.overflow('hidden')
    },

    ///SECONDARY ZONE

    secondaryZoneContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        columnGap: '12px',
        alignItems: 'center',
        minHeight: '48px',
        ...shorthands.padding('0px', '20px')
    },
    secondaryZoneMessageContainer: {
        display: 'block',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis'
    },

    secondaryZoneMessage: {
        fontWeight: 400
    }
});
