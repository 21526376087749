import { makeStyles, shorthands } from '@configs/theme/v9';

export const usePageStyles = makeStyles({
    page: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        height: '100%'
    },
    scrollable: {
        position: 'initial',
        overflowY: 'auto'
    },
    customActions: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('12px'),
        flexWrap: 'nowrap'
    }
});
