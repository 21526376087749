import * as React from 'react';
import { useMessageCategoriesListStyles } from './MessageCategoriesList.styles';
import { mergeClasses, Text } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { MessageCategoryData } from '@api/models';

export interface IMessageCategoriesListProps {
    categories: MessageCategoryData[];
    className?: string;
    maxItems?: number;
}

const MessageCategoriesList = ({
    categories,
    maxItems,
    className
}: IMessageCategoriesListProps): React.ReactElement => {
    const styles = useMessageCategoriesListStyles();
    const { t } = useTranslation();
    const max = maxItems || 2;
    return (
        <div className={mergeClasses(styles.root, className || '')}>
            {categories.length > max ? (
                <>
                    {categories.slice(0, max - 1).map((cat) => (
                        <Text as="span" key={cat.id} className={styles.category} title={cat.title}>
                            {cat.title}
                        </Text>
                    ))}
                    <Text
                        as="span"
                        key={'morecats'}
                        className={mergeClasses(styles.category, styles.more)}
                        title={`+ ${categories.length - max + 1} ${
                            t('MessageCategories.MoreLabel') || 'more'
                        }`}
                    >
                        {`+ ${categories.length - max + 1} ${
                            t('MessageCategories.MoreLabel') || 'more'
                        }`}
                    </Text>
                </>
            ) : (
                categories.map((cat) => (
                    <Text as="span" key={cat.id} className={styles.category} title={cat.title}>
                        {cat.title}
                    </Text>
                ))
            )}
        </div>
    );
};

export default MessageCategoriesList;
