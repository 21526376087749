import { makeStyles } from '@configs/theme/v9';

export const useCloseButtonStyles = makeStyles({
    headerCloseBtn: {
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap'
    },
    closeBtnCtn: {
        width: '30px'
    }
});
