import { makeStyles, shorthands } from '@configs/theme/v9';

export const useMessageViewHeaderStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        ...shorthands.gap('12px'),
        ...shorthands.padding('12px', '24px'),
        boxSizing: 'border-box'
    },
    flexRowItem: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        ...shorthands.gap('12px'),
        boxSizing: 'border-box'
    },
    flexGrowItem: {
        flexGrow: '1'
    },
    flexibleWidth: {
        width: 'auto',
        flexWrap: 'wrap'
    },
    autoWidth: {
        width: 'auto'
    }
});
