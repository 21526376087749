import { Dropdown, Option, Label, OptionGroup } from '@fluentui/react-components';
import * as React from 'react';
import { useDropdownStyles } from './Dropdown.styles';
import { CustomOptionDataModel } from '@models/data/CustomOptionDataModel';

export interface IDropdownOptions<T> {
    options?: CustomOptionDataModel<T>[];
    optionGroups?: { label: string; options: CustomOptionDataModel<T>[] }[];
    selectedValue?: CustomOptionDataModel<T>;
    label?: string;
    placeholder?: string;
    ariaLabel?: string;
    showAll?: boolean;
    onChanged: (selected: CustomOptionDataModel<T> | undefined) => void;
}

const DropDownPicker = <T,>({
    label,
    options,
    selectedValue,
    onChanged,
    optionGroups,
    ariaLabel,
    placeholder
}: IDropdownOptions<T>): React.ReactElement => {
    const styles = useDropdownStyles();

    const useGroups = !!optionGroups;

    const onPeriodSelect = React.useCallback(
        (key: string | undefined) => {
            if (!!key) {
                let values: CustomOptionDataModel<T>[] = [];
                if (!!optionGroups) {
                    optionGroups.forEach((c) => {
                        values.push(...c.options);
                    });
                } else if (!!options) {
                    values = options;
                }

                const p = values.find((c) => c.id === parseInt(key!));
                onChanged(p);
            } else {
                onChanged(undefined);
            }
        },
        [options, onChanged, optionGroups]
    );

    return (
        <div className={styles.root}>
            {!!label && (
                <Label size="large" className={styles.label} title={label}>
                    {label}
                </Label>
            )}
            <Dropdown
                className={styles.dropdown}
                onOptionSelect={(_, data) => onPeriodSelect(data.optionValue)}
                aria-label={ariaLabel}
                placeholder={placeholder}
                appearance="filled-darker"
                selectedOptions={!!selectedValue ? [selectedValue.id.toString()] : []}
                defaultValue={selectedValue?.title}
            >
                {useGroups
                    ? optionGroups.map((gr, i) => (
                          <OptionGroup key={i} label={gr.label}>
                              {gr.options.map((p) => (
                                  <Option
                                      key={p.id}
                                      aria-label={p.title}
                                      value={p.id.toString()}
                                      text={p.title}
                                  >
                                      {p.title}
                                  </Option>
                              ))}
                          </OptionGroup>
                      ))
                    : (options || []).map((p) => (
                          <Option
                              key={p.id}
                              aria-label={p.title}
                              value={p.id.toString()}
                              text={p.title}
                          >
                              {p.title}
                          </Option>
                      ))}
            </Dropdown>
        </div>
    );
};

export default DropDownPicker;
