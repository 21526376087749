import * as React from 'react';
import { useMessageInteractionActionsStyles } from './MessageInteractionActions.styles';
import { mergeClasses } from '@fluentui/react-components';

export interface MessageInteractionActionProps {
    fullWidth?: boolean;
}
const MessageInteractionActions: React.FC<
    React.PropsWithChildren<MessageInteractionActionProps>
> = ({ fullWidth, children }) => {
    const styles = useMessageInteractionActionsStyles();
    return (
        <div className={mergeClasses(styles.root, fullWidth ? styles.fullWidth : '')}>
            {children}
        </div>
    );
};

export default MessageInteractionActions;
