import { makeStyles, shorthands, tokens } from '@configs/theme/v9';

export const useListViewStyles = makeStyles({
    root: {
        display: 'block',
        ...shorthands.margin('8px', '20px', '20px')
    },
    rootCompact: {
        display: 'block',
        ...shorthands.margin('8px')
    },
    table: { minWidth: '800px' },

    header: {
        backgroundColor: tokens.colorNeutralBackground1,
        cursor: 'pointer'
    },
    critical: {
        backgroundColor: tokens.criticalCustomBackground
    },
    row: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.border('1px', 'solid', tokens.transparentWhite),
        cursor: 'pointer',
        ':hover': {
            color: tokens.colorNeutralForeground1Hover,
            backgroundColor: tokens.colorNeutralBackground1Hover
        }
    },
    cellText: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    firstColumn: {
        width: '30px'
    },
    titleIcon: {
        minWidth: '24px',
        width: '24px',
        height: '24px',
        color: tokens.colorNeutralForeground2Link
    },
    staticWidthSmallCell: {
        width: '100px'
    },
    staticWidthMidCell: {
        width: '170px'
    }
});
