import { makeStyles } from '@fluentui/react-components';

export const usePageStyles = makeStyles({
	page: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'transparent',
		height: '100%'
	},
	scrollable: {
		position: 'initial',
		overflowY: 'auto'
	}
});
