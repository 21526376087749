import * as React from 'react';
import TileCard from './TileCard';
import { MessageInstanceBasicContentModel } from '@models/data/MessageInstanceBasicContentModel';
import { useTileViewStyles } from './MessageTileView.styles';

export interface IMessagesTileViewProps {
    items: MessageInstanceBasicContentModel[];
    containerWidth: number;
    onClick: (message: MessageInstanceBasicContentModel) => void;
    compact?: boolean;
}

const TileView = ({
    items,
    containerWidth,
    onClick,
    compact
}: IMessagesTileViewProps): React.ReactElement => {
    const styles = useTileViewStyles();

    let width = '100%';
    if (containerWidth > 1500) {
        width = `${100 / Math.floor(containerWidth / 320)}%`;
    } else if (containerWidth > 1200) {
        width = '25%';
    } else if (containerWidth > 900) {
        width = `${100 / 3}%`;
    } else if (containerWidth > 600) {
        width = '50%';
    }

    return (
        <div className={!compact ? styles.container : styles.containerCompact}>
            {items.map((m) => (
                <TileCard
                    compact={compact}
                    key={m.id}
                    message={m}
                    width={width}
                    onClick={onClick}
                />
            ))}
        </div>
    );
};

export default TileView;
