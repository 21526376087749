import { apiClient } from '@api/services/client';
import { MessageInstanceBasicData } from '@api/models';
import { MessageRequestFilters } from '@api/models/MessageRequestFilters';
import { MessageInstanceFullDataModel } from '@api/models/MessageInstanceFullDataModel';
import { MessageRequestAdminFilters } from '@api/models/MessageRequestAdminFilters';
import { MessageInstanceAdminBasicData } from '@api/models/MessageInstanceAdminBasicData';
import { ConfirmCommunicationsModel } from '@api/models/ConfirmCommunicationsModel';

/** LEADER endpoints */

export const getMyMessages = async (
    filters: MessageRequestFilters
): Promise<MessageInstanceBasicData[]> => {
    const url = `/api/messages/my/active`;
    const res = await apiClient.post<MessageInstanceBasicData[]>(
        encodeURI(url),
        JSON.stringify(filters)
    );
    return res.data;
};

export const getMoreMessages = async (
    filters: MessageRequestFilters
): Promise<MessageInstanceBasicData[]> => {
    const url = `/api/messages/my/all`;
    const res = await apiClient.post<MessageInstanceBasicData[]>(
        encodeURI(url),
        JSON.stringify(filters)
    );
    return res.data;
};

export const getMyMessage = async (
    messageId: number
): Promise<MessageInstanceFullDataModel | undefined> => {
    const url = `/api/messages/my/message/${messageId}`;
    const res = await apiClient.get<MessageInstanceFullDataModel | undefined>(encodeURI(url));
    return res.data;
};

export const confirmMyMessage = async (
    messageId: number,
    model: ConfirmCommunicationsModel
): Promise<void> => {
    const url = `/api/messages/my/message/${messageId}/confirm`;
    await apiClient.post(encodeURI(url), JSON.stringify(model));
};

export const markMyMessageIrrelevant = async (
    messageId: number,
    feedback: string
): Promise<void> => {
    const url = `/api/messages/my/message/${messageId}/irrelevant`;
    await apiClient.post(encodeURI(url), JSON.stringify({ feedback }));
};

/** ADMIN endpoints */

export const getAdminMessages = async (
    filters: MessageRequestAdminFilters
): Promise<MessageInstanceAdminBasicData[]> => {
    const url = `api/admin/messages`;
    const res = await apiClient.post<MessageInstanceAdminBasicData[]>(
        encodeURI(url),
        JSON.stringify(filters)
    );
    return res.data;
};

export const getLeaderMessageByAdmin = async (
    messageId: number,
    leaderId: number
): Promise<MessageInstanceFullDataModel | undefined> => {
    const url = `api/admin/message/${messageId}`;
    const res = await apiClient.get<MessageInstanceFullDataModel | undefined>(encodeURI(url));
    return res.data;
};

export const confirmLeaderMessageByAdmin = async (
    messageId: number,
    model: ConfirmCommunicationsModel
): Promise<void> => {
    const url = `api/admin/message/${messageId}/confirm`;
    await apiClient.post(encodeURI(url), JSON.stringify(model));
};

export const markLeaderMessageIrrelevantByAdmin = async (
    messageId: number,
    feedback: string
): Promise<void> => {
    const url = `api/admin/message/${messageId}/irrelevant`;
    await apiClient.post(encodeURI(url), JSON.stringify({ feedback }));
};

/** APPROVER endpoints */

export const getApproverMessages = async (
    filters: MessageRequestFilters,
    sltId: number
): Promise<MessageInstanceBasicData[]> => {
    const url = `/api/approver/${sltId}/messages`;
    const res = await apiClient.post<MessageInstanceBasicData[]>(
        encodeURI(url),
        JSON.stringify(filters)
    );
    return res.data;
};

export const getSltMessageByApprover = async (
    messageId: number,
    sltId: number
): Promise<MessageInstanceFullDataModel | undefined> => {
    const url = `/api/approver/${sltId}/messages/${messageId}`;
    const res = await apiClient.get<MessageInstanceFullDataModel | undefined>(encodeURI(url));
    return res.data;
};

export const confirmSltMessageByApprover = async (
    messageId: number,
    sltId: number,
    model: ConfirmCommunicationsModel
): Promise<void> => {
    const url = `/api/approver/${sltId}/messages/${messageId}/confirm`;
    await apiClient.post(encodeURI(url), JSON.stringify(model));
};

export const markSltMessageIrrelevantByApprover = async (
    messageId: number,
    sltId: number,
    feedback: string
): Promise<void> => {
    const url = `/api/approver/${sltId}/messages/${messageId}/irrelevant`;
    await apiClient.post(encodeURI(url), JSON.stringify({ feedback }));
};
