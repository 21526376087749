import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const useStyles = makeStyles({
    errorMessage: {
        ...shorthands.margin('1rem'),
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        color: tokens.colorPaletteRedForeground3
    }
});