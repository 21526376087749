import { makeStyles, tokens } from '@configs/theme/v9';

export const useOtherColleaguesSectionStyles = makeStyles({
    personPicker: {
        '& div.ms-PickerPersona-container': {
            backgroundColor: tokens.colorNeutralBackground4
        },
        '& .ms-Persona-primaryText': {
            color: tokens.colorNeutralForeground2
        },
        '& .ms-Button': {
            color: tokens.colorNeutralForeground2
        },
        '& .ms-Button:hover': {
            color: tokens.colorNeutralForeground2Hover,
            backgroundColor: tokens.colorSubtleBackgroundHover
        }
    },
    pickerCallout: {
        '& .ms-Suggestions-spinner': {
            flexDirection: 'row'
        },
        '& .ms-Spinner-circle': {
            width: '20px',
            height: '20px'
        }
    }
});
