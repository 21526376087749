import { TitleValuePair } from '@models/data/TitleValuePair';

export const getfeedbackTypes = (
    translation: (key: string) => string
): TitleValuePair<string>[] => {
    return [
        {
            id: 'Tool feedback',
            title: translation('Common.Feedback.Dialog.FeedbackType.AppFeedbackOption')
        },
        {
            id: 'Message feedback',
            title: translation('Common.Feedback.Dialog.FeedbackType.MessageContentOption')
        }
    ];
};
