import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SizeModeType } from '@models/ui/SizeModeType';

import { routes } from '@configs/pages';

import { getUserPermissions } from '@api/services/permissions';
import { CurrentUserPermissions } from '@api/models';
import { loadMeta } from '../meta';
import { RootState } from '..';

const MOBILE_WIDTH_MAX = 760;
const TABLET_WIDTH_MAX = 1024;

/* Models */

export interface IAppConfigState {
    containerWidth: number;
    sizeMode: SizeModeType;
    initialized: boolean;
    allowedRoutes: string[];
    currentUserPermissions: CurrentUserPermissions;
}

const initialState: IAppConfigState = {
    sizeMode: SizeModeType.Full,
    initialized: false,
    containerWidth: 0,
    allowedRoutes: [],
    currentUserPermissions: {
        admin: false,
        approver: false
    }
};

/** Thunks */
const loadMyPermissions = createAsyncThunk<CurrentUserPermissions, void>(
    'loadMyPermissions',
    async (_, api) => {
        const permissions = await getUserPermissions();
        return permissions;
    }
);

export const initApp = createAsyncThunk<void, void, { state: RootState }>(
    'initApp',
    async (_, api) => {
        await Promise.all([api.dispatch(loadMyPermissions()), api.dispatch(loadMeta())]);
    }
);

/** Slice */
export const appSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        setAppUIConfig: (state, action: PayloadAction<{ width: number }>) => {
            state.containerWidth = action.payload.width;
            if (action.payload.width > TABLET_WIDTH_MAX) {
                state.sizeMode = SizeModeType.Full;
            } else if (action.payload.width < MOBILE_WIDTH_MAX) {
                state.sizeMode = SizeModeType.Mobile;
            } else {
                state.sizeMode = SizeModeType.Tablet;
            }
        },
        setPagesConfig: (state, action: PayloadAction<string[]>) => {
            state.allowedRoutes = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(initApp.fulfilled, (state, action) => {
                state.initialized = true;
            })
            .addCase(loadMyPermissions.fulfilled, (state, action) => {
                //Routes configuration based on permissions
                const allowedRoutes: string[] = [routes.myMessages, routes.moreMessages];
                if (action.payload.admin) {
                    allowedRoutes.push(routes.admin);
                }
                if (action.payload.approver) {
                    allowedRoutes.push(routes.designatedApprover);
                }

                state.allowedRoutes = allowedRoutes;
                state.currentUserPermissions = action.payload;
            });
    }
});

/** Selectors */
export const { setAppUIConfig } = appSlice.actions;

export default appSlice.reducer;
