import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { MessageFilteringModel } from '@models/data/MessageMetaFilteringModel';
import type { UserBasicData } from '@api/models/UserBasicData';

/* Models */

export interface IFiltersState {
    showFiltersPanel: boolean;
    messageFilters: MessageFilteringModel;
    //for admin mode
    filteredLeader: UserBasicData | undefined;
    filteredSLTLeader: UserBasicData | undefined;
    searchBoxValue: string | undefined;
}

const initialState: IFiltersState = {
    showFiltersPanel: false,
    messageFilters: {
        categories: [],
        strategicFocuses: [],
        importanceLevels: [],
        statuses: [],
        selectedPeriod: undefined
    },
    filteredLeader: undefined,
    searchBoxValue: undefined,
    filteredSLTLeader: undefined
};

/** Thunks */

/** Slice */
export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setPanelVisibility: (state, action: PayloadAction<boolean>) => {
            state.showFiltersPanel = action.payload;
        },
        resetFiltersState: (state, _action: PayloadAction<void>) => {
            state.showFiltersPanel = false;
            state.messageFilters = {
                categories: [],
                strategicFocuses: [],
                importanceLevels: [],
                statuses: [],
                selectedPeriod: undefined
            };
            state.filteredLeader = undefined;
            state.searchBoxValue = undefined;
            state.filteredSLTLeader = undefined;
        },
        applyMessageFilters: (state, action: PayloadAction<MessageFilteringModel>) => {
            state.messageFilters = action.payload;
            state.showFiltersPanel = false;
        },
        applyMessageAndLeaderFilters: (
            state,
            action: PayloadAction<{
                filters: MessageFilteringModel;
                leader: UserBasicData | undefined;
            }>
        ): void => {
            state.messageFilters = action.payload.filters;
            state.filteredLeader = action.payload.leader;
            state.showFiltersPanel = false;
        },
        setSearchBoxValue: (state, action: PayloadAction<string>) => {
            state.searchBoxValue = action.payload;
        },
        setLeaderFilter: (state, action: PayloadAction<UserBasicData | undefined>) => {
            state.filteredLeader = action.payload;
        },
        setSLTLeaderFilter: (state, action: PayloadAction<UserBasicData | undefined>) => {
            state.filteredSLTLeader = action.payload;
        }
    }
});

export const {
    setPanelVisibility,
    resetFiltersState,
    applyMessageFilters,
    setSearchBoxValue,
    setLeaderFilter,
    setSLTLeaderFilter
} = filtersSlice.actions;

export default filtersSlice.reducer;
