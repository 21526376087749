import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const useMessageContentStepStyles = makeStyles({
    root: {
        display: 'flex',
        ...shorthands.gap('24px'),
        ...shorthands.padding('24px'),
        flexDirection: 'row',
        boxSizing: 'border-box'
    },
    leftColumn: {
        maxWidth: '400px',
        width: '100%',
        textAlign: 'center'
    },
    fullWidthSection: {
        maxWidth: '100%',
        width: '100%',
        textAlign: 'center'
    },
    mainColumn: {
        rowGap: '16px',
        ...shorthands.flex(1),
        flexDirection: 'column',
        display: 'flex'
    },
    contentWrapper: {
        rowGap: '16px',
        ...shorthands.flex(1),
        flexDirection: 'column',
        display: 'flex'
    },
    messageContent: {
        backgroundColor: `${tokens.colorNeutralBackground1} !important`,
        color: `${tokens.colorNeutralForeground1} !important`,
        '& *': {
            backgroundColor: `inherit !important`,
            color: 'inherit !important'
        }
    }
});
