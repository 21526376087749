import {
    Theme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    teamsLightTheme
} from '@fluentui/react-components';

import { AppTheme } from './AppTheme';

const extendTheme = (theme: Theme, mode: 'dark' | 'contrast' | 'default'): AppTheme => {
    switch (mode) {
        case 'dark':
            return {
                ...theme,
                criticalCustomBackground: '#4a1e04',
                transparentWhite: 'transparent',
                imageBackground: '#FFFFFF',
                customShadow1: '0 1px 2px rgba(0,0,0,0.10)',
                customShadow2: '0 3px 4px rgba(0,0,0,0.15)',
                criticalMessageColor: '#FFFFFF'
            };
        case 'contrast':
            return {
                ...theme,
                criticalCustomBackground: '#000000',
                transparentWhite: '#FFFFFF',
                imageBackground: '#FFFFFF',
                customShadow1: '0 1px 2px rgba(0,0,0,0.10)',
                customShadow2: '0 3px 4px rgba(0,0,0,0.15)',
                criticalMessageColor: '#1AEBFF'
            };
        default:
            return {
                ...theme,
                criticalCustomBackground: '#FFF4CE',
                transparentWhite: 'transparent',
                imageBackground: 'transparent',
                customShadow1: '0 1px 2px rgba(0,0,0,0.10)',
                customShadow2: '0 3px 4px rgba(0,0,0,0.15)',
                criticalMessageColor: '#835C00'
            };
    }
};

export const getV9TeamsThemeByName = (name: string): AppTheme => {
    switch (name) {
        case 'dark':
            return extendTheme(teamsDarkTheme, 'dark');
        case 'contrast':
            return extendTheme(teamsHighContrastTheme, 'contrast');
        default:
            return extendTheme(teamsLightTheme, 'default');
    }
};
