export const CACHE_KEY = '[PLCT]';

type Nullable<T> = T | undefined | null;

export class CacheEntry<T> {
    constructor(
        public readonly expires: Nullable<Date>,
        public readonly value: Nullable<T>
    ) {}
}

class BrowserStorage {
    private storage = window.localStorage;

    private get key(): string {
        return `${CACHE_KEY}`;
    }

    private updateStorageEntry<T>(key: string, value: T, expiration: number | Date): void {
        try {
            const entry = JSON.parse(this.storage.getItem(this.key) || '{}') as Partial<{
                [key: string]: unknown;
            }>;
            entry[key] = this.asCacheEntry(value, expiration);
            this.storage.setItem(this.key, JSON.stringify(entry));
        } catch (e) {
            this.clear();
        }
    }

    private getStorageEntry<T>(key: string): Nullable<T> {
        try {
            const entry = JSON.parse(this.storage.getItem(this.key) || '{}') as Partial<{
                [key: string]: unknown;
            }>;
            return this.fromCacheEntry<T>(entry[key] as Nullable<CacheEntry<T>>);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    }

    private asCacheEntry<T>(item: T, expiration: number | Date = 0): CacheEntry<T> {
        let expDate: Nullable<Date>;
        if (typeof expiration === 'number' && expiration > 0) {
            const date = new Date();
            date.setMinutes(date.getMinutes() + expiration);
            expDate = date;
        }
        if (expiration instanceof Date && !isNaN(+expiration)) {
            expDate = expiration;
        }
        return new CacheEntry(expDate, item);
    }

    /**
     * Safely get value from CacheEntry.
     * If item has expired - returns `undefined`.
     */
    private fromCacheEntry<T>(entry: Nullable<CacheEntry<T>>): Nullable<T> {
        if (typeof entry === 'undefined' || entry === null) {
            return undefined;
        }
        return entry.expires && new Date(entry.expires) <= new Date() ? undefined : entry.value;
    }

    public get<T>(key: string): Nullable<T>;
    public get<T>(key: string, getter: () => T): T;
    public get<T>(...args: [string, (() => T)?]): Nullable<T> | T {
        const [key, getter] = args;
        const value = this.getStorageEntry<T>(key);
        if (typeof value === 'undefined' && typeof getter === 'function') {
            return getter();
        }
        return value;
    }

    public set<T>(key: string, item: T, expires: number | Date = 0): void {
        this.updateStorageEntry(key, item, expires);
    }
    public remove(key: string): void {
        try {
            const entry = JSON.parse(this.storage.getItem(this.key) || '{}') as Partial<{
                [key: string]: unknown;
            }>;
            delete entry[key];
            this.storage.setItem(this.key, JSON.stringify(entry));
        } catch (e) {
            console.error(e);
            this.clear();
        }
    }
    public clear(): void {
        try {
            this.storage.removeItem(this.key);
        } catch (e) {
            console.error(e);
        }
    }
}

export default new BrowserStorage();
