import { makeStyles, shorthands, tokens, typographyStyles } from '@configs/theme/v9';

export const useDirectReportsListStyles = makeStyles({
    subTitleBold: {
        ...shorthands.margin('12px', 0, 0),
        ...typographyStyles.subtitle2
    },
    directReportList: {
        display: 'flex',
        ...shorthands.padding(0),
        ...shorthands.gap('16px', '8px'),
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: '100%',
        width: '100% ',
        ...shorthands.margin('12px', 0)
    },
    directReportItem: {
        listStyleType: 'none'
    },
    fullCol: {
        flexBasis: '100%'
    },
    halfCol: {
        flexBasis: 'calc(50% - 12px)'
    },
    selectAllReports: {
        '& .fui-Checkbox__indicator': {
            height: '20px',
            width: '20px',
            ...shorthands.margin(tokens.spacingVerticalMNudge, tokens.spacingHorizontalMNudge),
            '& svg': {
                width: '16px',
                height: '16px'
            }
        },
        '& .fui-Persona__primaryText': {
            lineHeight: tokens.lineHeightBase500
        },
        ...shorthands.margin('12px', 0, 0)
    }
});
