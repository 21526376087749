import { getUserPhoto } from '@api/services/users';
import { Persona, PersonaProps } from '@fluentui/react-components';
import * as React from 'react';

export interface IUserInfoProps extends PersonaProps {
    userId?: string;
    photoDataUri?: string;
}

const UserInfo: React.FC<IUserInfoProps> = ({ userId, photoDataUri, ...props }) => {
    const [userPhotoSrc, setUserPhotoSrc] = React.useState<string | undefined>();

    React.useEffect(() => {
        (async () => {
            if (userId) {
                const response = await getUserPhoto(userId);
                if (response) {
                    setUserPhotoSrc(response);
                }
            }
        })();
    }, [userId]);

    return (
        <Persona
            {...props}
            avatar={{
                image: {
                    src: photoDataUri ?? userPhotoSrc
                }
            }}
        />
    );
};

export default UserInfo;
