import * as React from 'react';
import { useMessageStrategicFocusBadgeStyles } from './MessageStrategicFocusBadge.styles';
import { Text, mergeClasses } from '@fluentui/react-components';
import { MessageStrategicFocusData } from '@api/models';

export interface IMessageStrategicFocusBadgeProps {
    strategicFocus: MessageStrategicFocusData;
    className?: string;
}

const MessageStrategicFocusBadge = ({
    strategicFocus,
    className
}: IMessageStrategicFocusBadgeProps): React.ReactElement => {
    const styles = useMessageStrategicFocusBadgeStyles();

    return (
        <div className={mergeClasses(styles.focus, className || '')}>
            {strategicFocus.imageUrl && (
                <div className={styles.imgCtn}>
                    <img
                        className={styles.image}
                        src={strategicFocus.imageUrl}
                        alt={strategicFocus.title}
                    />
                </div>
            )}

            <Text as="span" className={styles.focusTitle} title={strategicFocus.title}>
                {strategicFocus.title}
            </Text>
        </div>
    );
};

export default MessageStrategicFocusBadge;
