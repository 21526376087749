//Redux

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector as useSelectorUntyped } from 'react-redux';

import appReducer from './app';
import metaReducer from './meta';
import messagesReducer from './messages';
import selectedMessageReducer from './selectedMessage';
import filtersReducer from './filters';

export const store = configureStore({
    reducer: {
        app: appReducer,
        meta: metaReducer,
        messages: messagesReducer,
        selectedMessage: selectedMessageReducer,
        filters: filtersReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => AppDispatch = () => useDispatch<AppDispatch>();
export const useStateSelector: TypedUseSelectorHook<RootState> = useSelectorUntyped;
