import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const useMessageCategoriesListStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('12px'),
        maxWidth: '100%',
        width: 'auto'
    },
    category: {
        backgroundColor: tokens.colorNeutralBackground6,
        color: tokens.colorNeutralForeground2,
        ...shorthands.padding('8px', '8px'),
        ...shorthands.borderRadius('4px'),

        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...shorthands.overflow('hidden')
    },
    more: {
        width: 'auto',
        ...shorthands.overflow('initial')
    }
});
