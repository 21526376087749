import { makeStyles, shorthands, tokens, typographyStyles } from '@configs/theme/v9';

export const useMessageAdditionalInfoStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        listStyleType: 'none',
        textAlign: 'left',
        ...shorthands.padding(0)
    },
    messageInfoItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        flexWrap: 'wrap',
        ...shorthands.gap('8px'),
        ...shorthands.padding('8px', 0)
    },
    messageInfoItemTitle: {
        ...typographyStyles.subtitle2
    },
    messageInfoItemValue: {
        ...typographyStyles.body2
    },
    userInfo: {
        '& .fui-Persona__primaryText': {
            ...typographyStyles.body2,
            lineHeight: tokens.lineHeightBase500
        }
    }
});
