import { MessagePeriodData } from '@api/models';

export const getPredefinedPeriods = (translation: (key: string) => string): MessagePeriodData[] => {
    const now = new Date();
    const last28Days = new Date(new Date().setDate(-28));
    const last12Months = new Date(new Date().setMonth(-12));
    const last3Months = new Date(new Date().setMonth(-3));

    return [
        {
            id: -100,
            title: translation('Periods.Last4Weeks'),
            endDateUTC: now.toJSON(),
            startDateUTC: last28Days.toJSON()
        },
        {
            id: -200,
            title: translation('Periods.Last3Months'),
            endDateUTC: now.toJSON(),
            startDateUTC: last3Months.toJSON()
        },
        {
            id: -300,
            title: translation('Periods.Last12Months'),
            endDateUTC: now.toJSON(),
            startDateUTC: last12Months.toJSON()
        }
    ];
};

export const getPeriodsFilterConfig = (
    translation: (key: string) => string,
    loaded: MessagePeriodData[]
): { label: string; options: MessagePeriodData[] }[] => {
    return [
        {
            label: translation('Periods.PeriodsGroups'),
            options: loaded
        },
        {
            label: translation('Periods.CalendarGroups'),
            options: getPredefinedPeriods(translation)
        }
    ];
};
