import * as React from 'react';
import {
    Combobox,
    Option,
    useId,
    Persona,
    Label,
    InteractionTag,
    InteractionTagPrimary,
    InteractionTagSecondary,
    Avatar,
    mergeClasses
} from '@fluentui/react-components';
import type { ComboboxProps } from '@fluentui/react-components';
import { useStyles } from './PeoplePicker.styles';
import { UserBasicData } from '@api/models/UserBasicData';
import { useDebouncedCallback } from 'use-debounce';
import { Search16Regular } from '@fluentui/react-icons';
import { getUsersPhotos } from '@api/services/users';

export interface IPeoplePickerProps {
    onSearchUsers: (text: string | undefined) => Promise<UserBasicData[]>;
    onSelectUser: (user: UserBasicData | undefined) => void;
    label?: string | undefined;
    placeholder?: string | undefined;
    selectedUser?: UserBasicData;
    noResultText?: string | undefined;
    className?: string | undefined;
    fullWidthMode?: boolean;
    preload?: boolean;
}

export const PeoplePicker = ({
    onSearchUsers,
    label,
    onSelectUser,
    selectedUser,
    placeholder,
    className,
    noResultText,
    fullWidthMode,
    preload
}: IPeoplePickerProps): React.ReactElement => {
    const comboId = useId('combo-peoplePicker');
    const styles = useStyles();

    const [tempValue, setTempValue] = React.useState<string | undefined>(selectedUser?.name);
    const [suggestions, setSuggestions] = React.useState<UserBasicData[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [preloaded, setPreloaded] = React.useState(false);
    const [userPhotos, setUserPhotos] = React.useState<Map<string, string>>(new Map());

    const onSearchValues = React.useCallback(
        async (searchVal: string | undefined) => {
            const res = await onSearchUsers(searchVal);
            setSuggestions(res);
            setLoading(false);
            setPreloaded(true);
        },
        [onSearchUsers]
    );
    const onSearchValuesDebounced = useDebouncedCallback(onSearchValues, 600);

    const onChange: ComboboxProps['onChange'] = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setLoading(true);
            const value = event.target.value;
            setTempValue(value);
            onSearchValuesDebounced(value);
        },
        [onSearchValuesDebounced]
    );

    const onOptionSelect: ComboboxProps['onOptionSelect'] = (event, data) => {
        const key = data.optionValue;
        if (!!key) {
            const p = suggestions.find((c) => c.id === parseInt(key));
            if (p) {
                p.photo = userPhotos.get(p?.aadId ?? '');
            }
            onSelectUser(p);
        }
    };

    const onResetUser = (): void => {
        setTempValue(undefined);
        onSelectUser(undefined);
    };

    React.useEffect(() => {
        (async () => {
            const photos = await getUsersPhotos(suggestions.map((x) => x.aadId));
            setUserPhotos(photos);
        })();
    }, [suggestions]);

    React.useEffect(() => {
        setTempValue(selectedUser?.name);
    }, [selectedUser]);

    React.useEffect(() => {
        if (preload && !preloaded) {
            onSearchValues(tempValue || '');
        }
    });

    if (preload && !preloaded) {
        return <></>;
    }

    return (
        <div className={mergeClasses(styles.root, className || '')}>
            {!!label && (
                <Label size="large" className={styles.label} title={label}>
                    {label}
                </Label>
            )}
            {selectedUser ? (
                <InteractionTag
                    className={fullWidthMode ? styles.selectedFullWidth : styles.selected}
                >
                    <InteractionTagPrimary
                        className={styles.option}
                        media={
                            <Avatar
                                name={selectedUser.name}
                                image={{ src: selectedUser.photo }}
                                color="colorful"
                            />
                        }
                        primaryText={selectedUser.name}
                        secondaryText={selectedUser.upn}
                    ></InteractionTagPrimary>
                    <InteractionTagSecondary aria-label="remove" onClick={onResetUser} />
                </InteractionTag>
            ) : (
                <Combobox
                    id={`${comboId}-default`}
                    onChange={onChange}
                    onOptionSelect={onOptionSelect}
                    defaultValue={tempValue}
                    placeholder={placeholder}
                    appearance="filled-darker"
                    className={styles.combobox}
                    expandIcon={<Search16Regular aria-label="Search" />}
                    listbox={{
                        // className: loading || (!tempValue && !preload) ? styles.hidden : ''
                        className: loading || (!tempValue && !preload) ? styles.hidden : ''
                    }}
                    freeform
                >
                    {suggestions.map((s, i) => (
                        <Option
                            key={s.id}
                            aria-label={s.name}
                            value={s.id.toString()}
                            text={s.name}
                            checkIcon={<></>}
                        >
                            <Persona
                                className={styles.option}
                                avatar={{
                                    color: 'colorful',
                                    'aria-hidden': true,
                                    className: styles.avatar,
                                    image: {
                                        src: userPhotos.get(s.aadId)
                                    }
                                }}
                                name={s.name}
                                secondaryText={s.upn}
                            />
                        </Option>
                    ))}
                    {!!noResultText && suggestions.length === 0 ? (
                        <Option key="no-results" text="">
                            <span className={styles.noResults}>{noResultText}</span>
                        </Option>
                    ) : null}
                </Combobox>
            )}
        </div>
    );
};
