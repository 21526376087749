import React, { useEffect } from 'react';
import { app, dialog } from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react-components';
import { useStyles } from './NotFoundPage.styles';

const NotFoundPage = (): React.ReactElement => {
    const { t } = useTranslation();
    const styles = useStyles();
    useEffect(() => {
        app.notifySuccess();
        if (app.getFrameContext() && app.getFrameContext() !== 'settings') {
            dialog.update.resize({
                height: 100,
                width: 300
            });
        }
    }, []);

    return <Text className={styles.errorMessage}>{t('PageNotFound')}</Text>;
};

export default NotFoundPage;
