import { mergeClasses } from '@fluentui/react-components';
import { useMessageMetaStyles } from './MessageMeta.styles';
import { MessageInstanceFullContentModel } from '@models/data/MessageInstanceFullContentModel';
import * as React from 'react';
import MessageCategoriesList from '@components/controls/MessageCategoriesList';
import MessageStrategicFocusBadge from '@components/controls/MessageStrategicFocusBadge';
import { MessagePublishDate } from '../MessagePublishDate';

export interface IMessageMetaProps {
    message: MessageInstanceFullContentModel;
    fullMode: boolean;
}

export const MessageMeta = ({ message, fullMode }: IMessageMetaProps): React.ReactElement => {
    const styles = useMessageMetaStyles();

    return (
        <div className={mergeClasses(styles.messageMeta, !fullMode ? styles.flexWrapItem : '')}>
            <MessageCategoriesList
                categories={message.categories}
                maxItems={!fullMode ? 3 : 2}
                className={fullMode ? '' : styles.flexibleWidth}
            />
            <MessageStrategicFocusBadge strategicFocus={message.strategicFocus} />
            {fullMode && <MessagePublishDate date={message.messagePublishingDateUTC} />}
        </div>
    );
};
