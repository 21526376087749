import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const useMobileActionsBarStyles = makeStyles({
    ///NAVIGATION
    navigationZonesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        boxSizing: 'border-box',
        ...shorthands.padding('0px', '8px'),
        height: '42px'
    },
    navigationMainZoneContainer: {
        flexGrow: '1',
        maxWidth: '100%'
    },
    navigationSecondaryZoneContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        columnGap: '8px'
    },
    ///ZONES Wrapper
    zonesContainer: {
        display: 'block',
        width: '100%',
        boxSizing: 'border-box'
    },

    ///MAIN ZONE
    mainZoneContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        columnGap: '2px',
        alignItems: 'center',
        boxSizing: 'border-box',
        backgroundColor: tokens.colorNeutralBackground1,
        height: '48px',
        ...shorthands.padding('0px', '8px')
    },
    mainZoneActionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        ...shorthands.gap('12px'),
        ...shorthands.overflow('hidden')
    },
    mainSubZoneContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        rowGap: '12px',
        alignItems: 'center',
        width: '100%',
        ...shorthands.padding('0px', '8px'),
        boxSizing: 'border-box',
        marginTop: '12px',
        paddingBottom: '12px',
        boxShadow: tokens.customShadow1
    },

    mainSubZoneActionContainer: {
        display: 'block',
        width: '100%',
        boxSizing: 'border-box'
    }
});
