import * as React from 'react';
import { useFullActionsBarStyles } from './FullActionsBar.styles';
import { Text } from '@fluentui/react-components';
import { MessagesViewType } from '@models/ui/MessagesViewType';
import MessageViewSwitcher from '@components/MessageViewSwitcher';
import { NavigationBar } from '@components/NavigationBar';

export interface IFullActionsBarProps {
    mainZoneMessage?: string;
    secondaryZoneMessage?: string;
    searchComponent?: React.ReactElement;
    additionalActionsComponent?: React.ReactElement;
    filtersComponent?: React.ReactElement;
    extraFiltersComponent?: React.ReactElement;
    filtersTagsComponent?: React.ReactElement;
    viewConfig?: {
        current: MessagesViewType;
        onChange: (view: MessagesViewType) => void;
    };
}

const FullActionBar = ({
    additionalActionsComponent,
    extraFiltersComponent,
    filtersComponent,
    filtersTagsComponent,
    mainZoneMessage,
    searchComponent,
    secondaryZoneMessage,
    viewConfig
}: IFullActionsBarProps): React.ReactElement => {
    const styles = useFullActionsBarStyles();
    return (
        <div>
            <div className={styles.navigationZonesContainer}>
                <div className={styles.navigationMainZoneContainer}>
                    <NavigationBar />
                </div>
            </div>
            <div className={styles.zonesContainer}>
                <div className={styles.mainZoneContainer}>
                    <div className={styles.mainZoneMessageContainer}>
                        {!!mainZoneMessage && (
                            <Text className={styles.mainZoneMessage} as="h4" size={300}>
                                {mainZoneMessage}
                            </Text>
                        )}
                    </div>
                    <div className={styles.mainZoneActionsContainer}>
                        {!!filtersTagsComponent && <div>{filtersTagsComponent}</div>}
                        {!!extraFiltersComponent && <div>{extraFiltersComponent}</div>}
                        {!!filtersComponent && <div>{filtersComponent}</div>}
                        {!!searchComponent && <div>{searchComponent}</div>}
                        {!!additionalActionsComponent && <div>{additionalActionsComponent}</div>}
                    </div>
                </div>
                <div className={styles.secondaryZoneContainer}>
                    <div className={styles.secondaryZoneMessageContainer}>
                        {!!secondaryZoneMessage && (
                            <Text className={styles.secondaryZoneMessage} as="h4">
                                {secondaryZoneMessage}
                            </Text>
                        )}
                    </div>
                    {!!viewConfig && (
                        <MessageViewSwitcher
                            active={viewConfig.current}
                            onViewChange={viewConfig.onChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FullActionBar;
