import * as React from 'react';
import { useMobileActionsBarStyles } from './MobileActionsBar.styles';
import { MessagesViewType } from '@models/ui/MessagesViewType';
import MessageViewSwitcher from '@components/MessageViewSwitcher';
import { NavigationBar } from '@components/NavigationBar';
import { Divider } from '@fluentui/react-components';

export interface IMobileActionsBarProps {
    mainZoneMessage?: string;
    searchComponent?: React.ReactElement;
    additionalActionsComponent?: React.ReactElement;
    filtersComponent?: React.ReactElement;
    extraFiltersComponent?: React.ReactElement;
    filtersTagsComponent?: React.ReactElement;
    viewConfig?: {
        current: MessagesViewType;
        onChange: (view: MessagesViewType) => void;
    };
}

const MobileActionsBar = ({
    additionalActionsComponent,
    extraFiltersComponent,
    filtersComponent,
    searchComponent,
    viewConfig
}: IMobileActionsBarProps): React.ReactElement => {
    const styles = useMobileActionsBarStyles();
    return (
        <div>
            <div className={styles.navigationZonesContainer}>
                <div className={styles.navigationMainZoneContainer}>
                    <NavigationBar asTags />
                </div>
            </div>

            <div className={styles.zonesContainer}>
                <Divider />
                <div className={styles.mainZoneContainer}>
                    <div className={styles.mainZoneActionContainer}>
                        {!!viewConfig && (
                            <MessageViewSwitcher
                                active={viewConfig.current}
                                onViewChange={viewConfig.onChange}
                            />
                        )}
                    </div>
                    <div className={styles.mainZoneActionContainer}>
                        {!!filtersComponent && filtersComponent}
                        {!!additionalActionsComponent && additionalActionsComponent}
                    </div>
                </div>
                <Divider />
                <div className={styles.mainSubZoneContainer}>
                    {!!extraFiltersComponent && (
                        <div className={styles.mainSubZoneActionContainer}>
                            {extraFiltersComponent}
                        </div>
                    )}
                    {!!searchComponent && (
                        <div className={styles.mainSubZoneActionContainer}>{searchComponent}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MobileActionsBar;
