import { makeStyles, tokens } from '@fluentui/react-components';
export const useStyles = makeStyles({
    errorMessage: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        color: tokens.colorPaletteRedForeground3
    }
});
