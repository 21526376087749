import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Text } from '@fluentui/react-components';
import { app, dialog } from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ErrorPage.styles';

const ErrorPage = (): React.ReactElement => {
    const { t } = useTranslation();
    const styles = useStyles();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const message = searchParams.get('message');
    useEffect(() => {
        app.notifySuccess();
        if (app.getFrameContext() && app.getFrameContext() !== 'settings') {
            dialog.update.resize({
                height: 100,
                width: 500
            });
        }
    }, []);

    function parseErrorMessage(): string {
        if (!!message) {
            return t(message) ?? message;
        } else if (id === '401') {
            return t('UnauthorizedErrorMessage');
        } else if (id === '403') {
            return t('ForbiddenErrorMessage');
        }

        return t('GeneralErrorMessage');
    }

    return <Text className={styles.errorMessage}>{parseErrorMessage()}</Text>;
};

export default ErrorPage;
