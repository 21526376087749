import { makeStyles, tokens, shorthands, typographyStyles } from '@configs/theme/v9';

export const useFiltersPanelStyles = makeStyles({
    filterButton: {
        backgroundColor: tokens.colorNeutralBackground6
    },
    iconOnlyBtn: {
        width: '36px',
        maxWidth: '36px',
        height: '32px'
    },
    panel: {
        width: '100%',
        maxWidth: '320px'
    },
    root: {
        display: 'flex',
        ...shorthands.gap('12px'),
        ...shorthands.padding('0px'),
        flexDirection: 'column',
        boxSizing: 'border-box',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
    footer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    header: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        ...shorthands.gap('12px'),
        ...shorthands.padding('12px', '24px'),
        boxSizing: 'border-box'
    },
    headerText: {
        ...typographyStyles.title3,
        ...shorthands.margin('0px')
    },
    headerCloseBtn: {
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap'
    },
    rightActions: {
        ...shorthands.gap('12px'),
        flexWrap: 'nowrap',
        display: 'flex'
    }
});
