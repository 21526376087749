import { makeStyles } from '@configs/theme/v9';

export const useSearchStyles = makeStyles({
    icon: {
        cursor: 'pointer'
    },
    iconCtn: {
        display: 'flex'
    }
});
