import * as React from 'react';
import { useMessageBannerStyles } from './MessageBanner.styles';
import { Warning24Filled } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react-components';

export interface IMessageContentStepProps {
    critical: boolean;
}

export const MessageBanner = ({ critical }: IMessageContentStepProps): React.ReactElement => {
    const styles = useMessageBannerStyles();
    const { t } = useTranslation();

    return critical ? (
        <div className={styles.critical}>
            <Warning24Filled />
            <Text as="span" title={t('MessageInteraction.CriticalMessage') ?? ''}>
                {t('MessageInteraction.CriticalMessage')}
            </Text>
        </div>
    ) : (
        <></>
    );
};
