import { themeToTokensObject, teamsLightTheme } from '@fluentui/react-components';
import { AppTheme } from './AppTheme';

const dummyTheme: AppTheme = {
    ...teamsLightTheme,
    criticalCustomBackground: '',
    transparentWhite: '',
    imageBackground: '',
    customShadow1: '',
    customShadow2: '',
    criticalMessageColor: ''
};

export const AppThemeTokens = themeToTokensObject(dummyTheme);
