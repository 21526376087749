import React from 'react';
import { Text } from '@fluentui/react-components';
import { useMessageAdditionalInfoStyles } from './MessageAdditionalInfoSection.styles';
import UserInfo from '@components/UserInfo';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getMessageStatusesMap } from '@configs/messageStatuses';
import { MessageInstanceFullContentModel } from '@models/data/MessageInstanceFullContentModel';
import { MessageInstanceLeaderStatus, MessageInstanceStatus } from '@api/models';
import { getMessageLeaderStatusesMap } from '@configs/messageLeaderStatuses';

interface MessageAdditionalInfoSectionProps {
    message: MessageInstanceFullContentModel;
    isAdminView?: boolean;
}

const getPublishDate = (date?: Date): string =>
    date ? moment(new Date(date)).format('DD/MM/yyyy') : '';

export const MessageAdditionalInfoSection: React.FC<MessageAdditionalInfoSectionProps> = ({
    message,
    isAdminView
}) => {
    const styles = useMessageAdditionalInfoStyles();
    const { t } = useTranslation();
    const showStatus = isAdminView || message.status === MessageInstanceStatus.Completed;
    const statuses = showStatus ? getMessageStatusesMap(t) : {};
    const leaderStatuses = isAdminView ? getMessageLeaderStatusesMap(t) : {};
    return (
        <ul className={styles.root}>
            {isAdminView && (
                <>
                    <li className={styles.messageInfoItem}>
                        <Text as="span" className={styles.messageInfoItemTitle}>
                            {t('MessageInteraction.MessageAdditionalInfoSection.Manager')}:
                        </Text>
                        <UserInfo
                            className={styles.userInfo}
                            userId={message.leader.aadId}
                            name={message.leader.name}
                            size="small"
                        ></UserInfo>
                    </li>
                    <li className={styles.messageInfoItem}>
                        <Text as="span" className={styles.messageInfoItemTitle}>
                            {t('MessageInteraction.MessageAdditionalInfoSection.ManagerStatus')}:
                        </Text>

                        <Text as="span" className={styles.messageInfoItemValue}>
                            {leaderStatuses[message.leaderStatus]}{' '}
                        </Text>
                        {message.cascadedBy &&
                            message.leaderStatus ===
                                MessageInstanceLeaderStatus.CascadedByLeader && (
                                <UserInfo
                                    className={styles.userInfo}
                                    userId={message.cascadedBy?.aadId}
                                    name={message.cascadedBy?.name}
                                    size="small"
                                />
                            )}
                    </li>
                </>
            )}
            <li className={styles.messageInfoItem}>
                <Text as="span" className={styles.messageInfoItemTitle}>
                    {t('MessageInteraction.MessageAdditionalInfoSection.Created')}:
                </Text>
                <Text as="span" className={styles.messageInfoItemValue}>
                    {getPublishDate(message.messagePublishingDateUTC)}
                </Text>
            </li>
            <li className={styles.messageInfoItem}>
                <Text as="span" className={styles.messageInfoItemTitle}>
                    {isAdminView
                        ? t('MessageInteraction.MessageAdditionalInfoSection.ManagerDateReceived')
                        : t('MessageInteraction.MessageAdditionalInfoSection.YouDateReceived')}
                    :
                </Text>
                <Text as="span" className={styles.messageInfoItemValue}>
                    {getPublishDate(message.instancePublishingDateUTC)}
                </Text>
            </li>
            {showStatus && (
                <li className={styles.messageInfoItem}>
                    <Text as="span" className={styles.messageInfoItemTitle}>
                        {t('MessageInteraction.MessageAdditionalInfoSection.Status')}:
                    </Text>

                    <Text as="span" className={styles.messageInfoItemValue}>
                        {statuses[message.status]}
                    </Text>
                </li>
            )}
        </ul>
    );
};
