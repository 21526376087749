import * as React from 'react';
import getClass from './Loader.styles';

import { Spinner } from '@fluentui/react-components';

export interface ILoaderProps {
    message: string;
}

const Loader = ({ message }: ILoaderProps): React.ReactElement => (

    <div className={getClass()}>
        <Spinner size="large" label={message} />
    </div>
);

export default React.memo(Loader);
