import { Input, InputOnChangeData, useId } from '@fluentui/react-components';
import { Search16Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useSearchStyles } from './Search.styles';

export interface ISearchProps {
    placeholder: string;
    value: string | undefined;
    ariaLabel?: string | undefined;
    onChanged: (value: string) => void;
    iconBefore?: boolean;
    iconAfter?: boolean;
    dark?: boolean;
    className?: string;
}

const Search = ({
    placeholder,
    value,
    onChanged,
    ariaLabel,
    className,
    iconAfter
}: ISearchProps): React.ReactElement => {
    const searchId = useId('content-after');
    const [sbVal, setSBVal] = React.useState(value);

    const styles = useSearchStyles();

    const runSearch = useDebouncedCallback(
        React.useCallback(async () => {
            onChanged(sbVal || '');
        }, [onChanged, sbVal]),
        700
    );

    const onChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
        setSBVal(data.value);
        runSearch();
    };

    return (
        <Input
            className={className ? className : ''}
            placeholder={placeholder}
            onChange={onChange}
            defaultValue={value}
            appearance={'filled-darker'}
            contentAfter={
                iconAfter ? (
                    <label htmlFor={searchId} className={styles.iconCtn}>
                        <Search16Regular className={styles.icon} aria-label={ariaLabel} />
                    </label>
                ) : undefined
            }
            contentBefore={
                !iconAfter ? (
                    <label htmlFor={searchId} className={styles.iconCtn}>
                        <Search16Regular className={styles.icon} aria-label={ariaLabel} />
                    </label>
                ) : undefined
            }
            id={searchId}
        />
    );
};

export default Search;
