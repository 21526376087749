import React from 'react';
import { Field, Switch, Textarea, TextareaOnChangeData } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

interface IAnnotationTextSectionProps {
    onAnnotationChange: (
        ev: React.ChangeEvent<HTMLTextAreaElement>,
        data: TextareaOnChangeData
    ) => void;
    className?: string;
}

const AnnotationTextSection: React.FC<IAnnotationTextSectionProps> = ({
    onAnnotationChange,
    className
}) => {
    const { t } = useTranslation();
    const [annotationEnabled, setAnnotationEnabled] = React.useState(false);

    const onAddAnnotationChange = React.useCallback(
        (ev: { currentTarget: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
            setAnnotationEnabled(ev.currentTarget.checked);
        },
        []
    );
    return (
        <section className={className}>
            <Switch
                checked={annotationEnabled}
                onChange={onAddAnnotationChange}
                label={t('MessageInteraction.CommsStep.AddAnnotationForPeopleLeaders')}
            />
            {annotationEnabled && (
                <Field label={t('MessageInteraction.CommsStep.AnnotationForPeopleLeadersInfo')}>
                    <Textarea
                        placeholder={
                            t('MessageInteraction.CommsStep.AnnotationTextAreaPlaceholder') ?? ''
                        }
                        onChange={onAnnotationChange}
                        rows={6}
                        appearance="filled-darker"
                    />
                </Field>
            )}
        </section>
    );
};

export default AnnotationTextSection;
