import * as React from 'react';
import moment from 'moment';
import { Warning24Filled } from '@fluentui/react-icons';
import { MessageInstanceBasicContentModel } from '@models/data/MessageInstanceBasicContentModel';
import { useTileCardStyles, interactionClass } from './TileCard.styles';
import { mergeClasses, Text } from '@fluentui/react-components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MessageCategoriesList from '@components/controls/MessageCategoriesList';
import MessageStrategicFocusBadge from '@components/controls/MessageStrategicFocusBadge';

export interface IMessageTileCardProps {
    message: MessageInstanceBasicContentModel;
    width: number | string;
    onClick: (message: MessageInstanceBasicContentModel) => void;
    compact?: boolean;
}

const MessageTileCard = ({
    message,
    width,
    onClick,
    compact
}: IMessageTileCardProps): React.ReactElement => {
    const styles = useTileCardStyles();
    const { t } = useTranslation();
    const shortPublishedDate = moment(message.messagePublishingDateUTC).format('DD/MM/yyyy');
    const criticalClass = message.critical ? styles.critical : '';

    const onClickMessage = useCallback(() => onClick(message), [message, onClick]);

    return (
        <div className={!compact ? styles.root : styles.rootCompact} style={{ flexBasis: width }}>
            <button
                type="button"
                className={mergeClasses(styles.inner, criticalClass)}
                onClick={onClickMessage}
            >
                <div className={styles.titleWrapper}>
                    <Text
                        as="h5"
                        size={400}
                        title={message.title}
                        className={mergeClasses(styles.title, styles.multiLine, interactionClass)}
                    >
                        {message.title}
                    </Text>
                    {message.critical && (
                        <Warning24Filled
                            className={mergeClasses(styles.titleIcon, interactionClass)}
                        />
                    )}
                </div>
                <Text
                    as="p"
                    className={mergeClasses(styles.description, styles.multiLine, interactionClass)}
                >
                    {message.previewText}
                </Text>
                <MessageCategoriesList categories={message.categories} />
                <div className={styles.footerWrapper}>
                    <MessageStrategicFocusBadge strategicFocus={message.strategicFocus} />
                    <div>
                        <Text as="span" className={interactionClass} title={shortPublishedDate}>
                            <strong>{t('Common.MessageListView.CreationDateHeader')}</strong>
                            <span>: </span>
                            {shortPublishedDate}
                        </Text>
                    </div>
                </div>
            </button>
        </div>
    );
};

export default MessageTileCard;
