import * as React from 'react';
import { useEffect } from 'react';
import { usePageStyles } from './AdminPage.styles';
import { MessageAdminListView } from '@components/MessageListView';
import { useAppDispatch, useStateSelector } from '@state';

import { loadMessagesForAdmin } from '@state/messages';
import ActionsPanel from '@components/ActionsBar';
import { MessageFiltersPanelAction } from '@components/MessageFiltersPanelAction';
import { MessageSearchBox } from '@components/controls/MessageSearchBox';

import { useTranslation } from 'react-i18next';
import { withPageLayout } from '../PageLayout';
import { loadMessageByAdmin } from '@state/selectedMessage';
import { Button, mergeClasses } from '@fluentui/react-components';
import Loader from '@components/controls/common/Loader';
import MessageFilterTags from '@components/MessageFilterTags';
import { SizeModeType } from '@models/ui/SizeModeType';
import { MessageInstanceAdminBasicData } from '@api/models/MessageInstanceAdminBasicData';
import { useScrollBarStyle } from '@components/controls/common/scrollbar.styles';

const AdminPage: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const styles = usePageStyles();
    const scrollBarStyle = useScrollBarStyle();

    const sizeMode = useStateSelector((c) => c.app.sizeMode);
    const adminMessages = useStateSelector((x) => x.messages.adminMessages);
    const loading = useStateSelector((x) => x.messages.loading);
    const links = useStateSelector((x) => x.meta.links);

    const loadContent = React.useCallback(() => dispatch(loadMessagesForAdmin()), [dispatch]);
    const loadMessage = React.useCallback(
        (m: MessageInstanceAdminBasicData) => {
            dispatch(loadMessageByAdmin(m));
        },
        [dispatch]
    );

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    const additionalActionsComponent =
        sizeMode === SizeModeType.Full ? (
            <div className={styles.customActions}>
                <Button as="a" appearance="secondary" href={links?.report} target="blank">
                    {t('AdminPage.Actions.Report.Label')}
                </Button>
                <Button as="a" appearance="secondary" href={links?.feedback} target="blank">
                    {t('AdminPage.Actions.Report.Feedback')}
                </Button>
                <Button as="a" appearance="secondary" href={links?.configure} target="blank">
                    {t('AdminPage.Actions.Report.Configure')}
                </Button>
            </div>
        ) : (
            <></>
        );
    const filtersComponent = (
        <MessageFiltersPanelAction
            adminMode={true}
            applyFiltersCallback={loadContent}
            iconOnlyButton={sizeMode !== SizeModeType.Full}
        />
    );
    const filtersTagsComponent = <MessageFilterTags changeFiltersCallback={loadContent} />;
    const searchComponent = <MessageSearchBox searchValueChangedCallback={loadContent} />;

    return (
        <div className={styles.page}>
            <ActionsPanel
                secondaryZoneMessage={t('AdminPage.SecondaryMessage') || ''}
                sizeMode={sizeMode}
                filtersTagsComponent={filtersTagsComponent}
                additionalActionsComponent={additionalActionsComponent}
                searchComponent={searchComponent}
                filtersComponent={filtersComponent}
            />

            {loading ? (
                <Loader message={t('AdminPage.MessagesLoading') || ''} />
            ) : (
                <div className={mergeClasses(styles.scrollable, scrollBarStyle)}>
                    <MessageAdminListView
                        compact={sizeMode === SizeModeType.Mobile}
                        items={adminMessages}
                        onClick={loadMessage}
                    />
                </div>
            )}
        </div>
    );
};

export default withPageLayout(AdminPage);
