import { apiClient, cachedApiClient } from '@api/services/client';
import { UserBasicData } from '@api/models/UserBasicData';
import { UserPhotoModel } from '@api/models/UserPhotoModel';

export const searchUsers = async (text: string | undefined): Promise<UserBasicData[]> => {
    const url = `/api/users/search/all?searchText=${text}`;
    const response = await apiClient.get<UserBasicData[]>(encodeURI(url));
    return response.data;
};

export const searchLeaders = async (text: string | undefined): Promise<UserBasicData[]> => {
    const url = `/api/users/search/leaders?searchText=${text}`;
    const response = await apiClient.get<UserBasicData[]>(encodeURI(url));
    return response.data;
};

export const searchSLTUsers = async (text: string | undefined): Promise<UserBasicData[]> => {
    const url = `/api/users/search/slt?searchText=${text}`;
    const response = await apiClient.get<UserBasicData[]>(encodeURI(url));
    return response.data;
};

export const getUserPhoto = async (userId: string): Promise<string> => {
    const url = `/api/users/${userId}/photo/base64`;
    const response = await cachedApiClient.get<UserPhotoModel>(encodeURI(url), false);
    return response.data?.dataUri;
};

export const getUsersPhotos = async (userAADIds: string[]): Promise<Map<string, string>> => {
    const url = `/api/users/photos/base64`;
    const photos = new Map();

    if (userAADIds.length) {
        const response = await apiClient.post<UserPhotoModel[]>(
            encodeURI(url),
            JSON.stringify({ userAADIds }),
            false
        );

        if (response.data) {
            response.data.forEach((photoData) => {
                photos.set(photoData.userId, photoData.dataUri);
            });
        }
    }

    return photos;
};
