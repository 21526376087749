import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { type RootState } from '..';

import {
    getLeaderMessageByAdmin,
    getSltMessageByApprover,
    getMyMessage,
    markMyMessageIrrelevant,
    markSltMessageIrrelevantByApprover,
    markLeaderMessageIrrelevantByAdmin,
    confirmMyMessage,
    confirmLeaderMessageByAdmin,
    confirmSltMessageByApprover
} from '@api/services/messages';

import { type IMetaState } from '@state/meta';
import { type MessageInstanceFullContentModel } from '@models/data/MessageInstanceFullContentModel';
import {
    MessageInstanceStatus,
    type MessageInstanceFullDataModel,
    MessageInstanceLeaderStatus
} from '@api/models';
import { MessageInteractionMode } from '@models/data/MessageInteractionMode';
import { type MessageInstanceAdminBasicData } from '@api/models/MessageInstanceAdminBasicData';
import { ConfirmCommunicationsModel } from '@api/models/ConfirmCommunicationsModel';

/* Models */

export interface ISelectedMessageState {
    message: MessageInstanceFullContentModel | undefined;
    mode: MessageInteractionMode;
    processingType: 'loading' | 'irrelevant' | 'confirmation' | 'none';
    showMessageView: boolean;
    previewInitialized: boolean;
}

const initialState: ISelectedMessageState = {
    message: undefined,
    mode: MessageInteractionMode.Leader,
    processingType: 'none',
    showMessageView: false,
    previewInitialized: false
};

/* Thunks */

const processResult = (
    message: MessageInstanceFullDataModel | undefined,
    meta: IMetaState
): MessageInstanceFullContentModel | undefined => {
    if (!message) {
        return undefined;
    } else {
        const categories = meta.categories;
        const strategicFocuses = meta.strategicFocuses;
        const mCategories = categories.filter((c) => message.categoriesIds.includes(c.id));
        const mValue = strategicFocuses.find((value) => value.id === message.strategicFocusId);
        return {
            ...message,
            categories: mCategories,
            strategicFocus: mValue || {
                id: -1,
                title: '',
                imageUrl: ''
            }
        };
    }
};

const markMessageIrrelevant = createAsyncThunk<void, string, { state: RootState }>(
    'markMessageIrrelevant',
    async (feedback, api) => {
        const state = api.getState().selectedMessage;
        const id = state.message?.id;
        const mode = state.mode;
        if (id) {
            switch (mode) {
                case MessageInteractionMode.Leader: {
                    await markMyMessageIrrelevant(id, feedback);
                    break;
                }
                case MessageInteractionMode.Admin: {
                    await markLeaderMessageIrrelevantByAdmin(id, feedback);
                    break;
                }

                case MessageInteractionMode.Approver: {
                    const sltId = api.getState().filters.filteredSLTLeader?.id;
                    if (sltId) {
                        await markSltMessageIrrelevantByApprover(id, sltId, feedback);
                    }
                    break;
                }
            }
        }
    }
);

const confirmCommunication = createAsyncThunk<
    void,
    ConfirmCommunicationsModel,
    { state: RootState }
>('confirmCommunication', async (model, api) => {
    const state = api.getState().selectedMessage;
    const id = state.message?.id;
    const mode = state.mode;
    if (id) {
        switch (mode) {
            case MessageInteractionMode.Leader: {
                await confirmMyMessage(id, model);
                break;
            }
            case MessageInteractionMode.Admin: {
                await confirmLeaderMessageByAdmin(id, model);
                break;
            }

            case MessageInteractionMode.Approver: {
                const sltId = api.getState().filters.filteredSLTLeader?.id;
                if (sltId) {
                    await confirmSltMessageByApprover(id, sltId, model);
                }
                break;
            }
        }
    }
});

const loadMyMessage = createAsyncThunk<
    MessageInstanceFullContentModel | undefined,
    number,
    { state: RootState }
>('myMessage', async (id, api) => {
    const m = await getMyMessage(id);
    return processResult(m, api.getState().meta);
});

const loadMessageByApprover = createAsyncThunk<
    MessageInstanceFullContentModel | undefined,
    number,
    { state: RootState }
>('approverMessage', async (id, api) => {
    const state = api.getState();
    if (state.filters.filteredSLTLeader) {
        const m = await getSltMessageByApprover(id, state.filters.filteredSLTLeader.id);
        return processResult(m, api.getState().meta);
    } else {
        return processResult(undefined, api.getState().meta);
    }
});

const loadMessageByAdmin = createAsyncThunk<
    MessageInstanceFullContentModel | undefined,
    MessageInstanceAdminBasicData,
    { state: RootState }
>('adminMessage', async (m, api) => {
    const res = await getLeaderMessageByAdmin(m.id, m.leader.id);
    return processResult(res, api.getState().meta);
});

/** Slice */
export const selectedMessageSlice = createSlice({
    name: 'selectedMessage',
    initialState,
    reducers: {
        cancelMessageInteraction: (state) => {
            state.processingType = 'none';
            state.showMessageView = false;
            state.message = undefined;
        },
        setPreviewInitialized: (state) => {
            state.previewInitialized = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadMyMessage.fulfilled, (state, action) => {
                state.message = action.payload;
                state.mode =
                    state.message &&
                    state.message.status !== MessageInstanceStatus.Completed &&
                    state.message.leaderStatus !== MessageInstanceLeaderStatus.Irrelevant
                        ? MessageInteractionMode.Leader
                        : MessageInteractionMode.ReadOnly;
                state.processingType = 'none';
            })
            .addCase(loadMyMessage.pending, (state) => {
                state.processingType = 'loading';
                state.showMessageView = true;
            })
            .addCase(loadMessageByApprover.fulfilled, (state, action) => {
                state.message = action.payload;

                state.mode =
                    state.message &&
                    state.message.status !== MessageInstanceStatus.Completed &&
                    state.message.leaderStatus !== MessageInstanceLeaderStatus.Irrelevant
                        ? MessageInteractionMode.Approver
                        : MessageInteractionMode.ReadOnly;

                state.processingType = 'none';
            })
            .addCase(loadMessageByApprover.pending, (state) => {
                state.processingType = 'loading';
                state.showMessageView = true;
            })
            .addCase(loadMessageByAdmin.fulfilled, (state, action) => {
                state.message = action.payload;
                state.mode =
                    state.message && state.message.status !== MessageInstanceStatus.Completed
                        ? MessageInteractionMode.Admin
                        : MessageInteractionMode.ReadOnly;
                state.processingType = 'none';
            })
            .addCase(loadMessageByAdmin.pending, (state) => {
                state.processingType = 'loading';
                state.showMessageView = true;
            })
            .addCase(markMessageIrrelevant.pending, (state) => {
                state.processingType = 'irrelevant';
            })
            .addCase(markMessageIrrelevant.fulfilled, (state) => {
                state.processingType = 'none';
                state.showMessageView = false;
                state.message = undefined;
            })
            .addCase(confirmCommunication.pending, (state) => {
                state.processingType = 'confirmation';
            })
            .addCase(confirmCommunication.fulfilled, (state) => {
                state.processingType = 'none';
                state.showMessageView = false;
                state.message = undefined;
            });
    }
});

/** Exports */
export {
    loadMyMessage,
    loadMessageByAdmin,
    loadMessageByApprover,
    markMessageIrrelevant,
    confirmCommunication
};
export const { cancelMessageInteraction, setPreviewInitialized } = selectedMessageSlice.actions;
export default selectedMessageSlice.reducer;
