import { Text } from '@fluentui/react-components';
import * as React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMessagePublishDateStyles } from './MessagePublishDate.styles';

export interface IMessagePublishDateProps {
    date?: Date;
}

export const MessagePublishDate = ({ date }: IMessagePublishDateProps): React.ReactElement => {
    const styles = useMessagePublishDateStyles();
    const { t } = useTranslation();
    const shortPublishedDate = date ? moment(new Date(date)).format('DD/MM/yyyy') : '';

    return (
        <div className={styles.root}>
            <strong>{t('Common.MessageListView.CreationDateHeader')}</strong>
            <span>: </span>
            <Text className={styles.date} as="p" title={shortPublishedDate}>
                {shortPublishedDate}
            </Text>
        </div>
    );
};
