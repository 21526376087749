import { t } from 'i18next';
import { PageInfo } from '@models/ui/PageInfo';

export const routes = {
    myMessages: '/mymessages',
    myMessagesid: '/mymessages/:id',
    moreMessages: '/moremessages',
    errorPage: '/errorpage',
    errorPageid: '/errorpage/:id',
    admin: '/admin',
    designatedApprover: '/designatedapprover'
};

const myMessagePageInfo: PageInfo = {
    title: t('MyMessagesPage.PageTitle'),
    ariaLabel: t('MyMessagesPage.PageAriaLabel')
};

const moreMessagePageInfo: PageInfo = {
    title: t('MoreMessagesPage.PageTitle'),
    ariaLabel: t('MoreMessagesPage.PageAriaLabel')
};

const adminPageInfo: PageInfo = {
    title: t('AdminPage.PageTitle'),
    ariaLabel: t('AdminPage.PageAriaLabel')
};

const designatedApproverPageInfo: PageInfo = {
    title: t('DesignatedApproverPage.PageTitle'),
    ariaLabel: t('DesignatedApproverPage.PageAriaLabel')
};

export const routePagesMap = {
    [routes.myMessages]: myMessagePageInfo,
    [routes.moreMessages]: moreMessagePageInfo,
    [routes.admin]: adminPageInfo,
    [routes.designatedApprover]: designatedApproverPageInfo
};
