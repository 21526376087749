import { makeStyles, tokens, shorthands } from '@configs/theme/v9';

export const useNavBarStyles = makeStyles({
    root: {
        alignItems: 'center',
        overflowX: 'auto',
        maxWidth: '100%',
        height: 'fit-content',
        minWidth: '150px',
        width: '100%'
    },
    tagGroup: {
        ...shorthands.overflow('hidden'),
        zIndex: 0, // stop the browser resize handle from piercing the overflow menu
        height: '100%',
        width: 'calc(100% - 0px)',
        maxWidth: '100%',
        boxSizing: 'content-box',
        justifyContent: 'left',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center'
    },
    tag: {
        cursor: 'pointer',
        ':hover': {
            color: tokens.colorCompoundBrandForeground1Hover,
            backgroundColor: tokens.colorBrandBackground2Hover
        }
    },
    activeTag: {
        backgroundColor: tokens.colorNeutralBackground5Selected,
        color: tokens.colorNeutralForeground3BrandSelected
    }
});

export const useOverflowMenuStyles = makeStyles({
    menu: {
        backgroundColor: tokens.colorNeutralBackground1
    },
    menuButton: {
        alignSelf: 'center'
    }
});
